<template>
  <div class="create-court-page">
    <HomeIcon :showArrow="true" to="adm-dashboard" />

    <form @submit.prevent="createCourt" class="form-container">
      <h1>Criar Nova Quadra</h1>

      <!-- Loader para a criação de quadra -->
      <div v-if="loadingCreate" class="loader-container">
        <div class="loader"></div>
        <p>Enviando dados...</p>
      </div>

      <div v-if="!loadingCreate">
        <div class="form-group">
          <label for="nome">Nome da Quadra</label>
          <input
            v-model="state.nome"
            type="text"
            id="nome"
            placeholder="Digite o nome da quadra"
            @blur="validateField('nome')"
          />
          <span v-if="state.errors.nome" class="error">{{ state.errors.nome }}</span>
        </div>

        <div class="form-group">
          <label for="patrocinador">Patrocinador</label>
          <select v-model="state.idPatrocinador" id="patrocinador" @blur="validateField('idPatrocinador')">
            <option value="" disabled>Selecione um patrocinador</option>
            <option v-for="patrocinador in patrocinadores" :key="patrocinador.id" :value="patrocinador.id">
              {{ patrocinador.nome }}
            </option>
          </select>
          <span v-if="state.errors.idPatrocinador" class="error">{{ state.errors.idPatrocinador }}</span>
        </div>

        <button type="submit" class="button">Criar Quadra</button>
      </div>
    </form>

    <!-- Tabela de quadras -->
    <div class="courts-list">

      <!-- Loader global enquanto os dados estão sendo carregados -->
    <div v-if="loadingData" class="loader-container">
      <div class="loader"></div>
      <p>Carregando dados...</p>
    </div>
      
      <!-- Loader durante a exclusão da quadra -->
      <div v-if="loadingDelete" class="loader-container">
        <div class="loader"></div>
        <p>Excluindo quadra...</p>
      </div>

      <div v-else>
        <h2>Quadras Cadastradas</h2>
      <table>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Patrocinador</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="court in courts" :key="court.id">
            <td>{{ court.nome }}</td>
            <td>{{ court.patrocinador.nome }}</td>
            <td>
              <!-- Botão de deletar com loader -->
              <div v-if="loadingDelete && deletingCourtId === court.id" class="loader-container">
                  <div class="loader small"></div> <!-- Loader pequeno enquanto exclui -->
                </div>
                <div v-else>
              <font-awesome-icon icon="trash" class="icon-trash" @click="confirmDeleteCourt(court.id)" />
            </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    </div>

    <!-- Modal de confirmação para excluir quadra -->
    <div v-if="showConfirmation" class="confirmation-modal">
      <div class="modal-content">
        <h3>Confirmar Exclusão</h3>
        <p>Deseja realmente excluir esta quadra?</p>
        <div class="modal-actions">
          <button @click="cancelDeleteCourt" class="btn-cancel">Cancelar</button>
          <button @click="confirmDelete" class="btn-confirm">Confirmar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import axios from 'axios';
import { defineComponent, reactive, ref, onMounted } from 'vue';
import HomeIcon from '@/components/HomeIcon.vue';

interface Patrocinador {
  id: number;
  nome: string;
}

interface Court {
  id: number;
  nome: string;
  patrocinador: Patrocinador;
}

export default defineComponent({
  name: 'CreateCourtPage',
  components: {
    HomeIcon
  },
  setup() {
    const patrocinadores = ref<Patrocinador[]>([]);
    const courts = ref<Court[]>([]);
    const showConfirmation = ref(false);
    const deletingCourtId = ref<number | null>(null);
    const loadingData = ref(true);  // Loader para carregar dados
    const loadingCreate = ref(false); // Loader durante a criação de quadra
    const loadingDelete = ref(false); // Loader durante a exclusão de quadra

    const state = reactive({
      nome: '',
      idPatrocinador: '',
      errors: {
        nome: '',
        idPatrocinador: ''
      }
    });

    const schema = {
      nome: (value: string) => value.trim().length > 0 || 'O nome é obrigatório',
      idPatrocinador: (value: string | number) =>
        String(value).length > 0 || 'Selecione um patrocinador'
    };

    const validateField = (field: keyof typeof state) => {
      const validationFunction = schema[field as keyof typeof schema];
      const value = state[field as keyof typeof state];

      const errorMessage = typeof value === 'string'
        ? validationFunction(value.trim())
        : validationFunction(String(value));

      state.errors[field as keyof typeof state.errors] = errorMessage !== true ? errorMessage : '';
    };

    // Função para criar quadra
    const createCourt = async () => {
      validateField('nome');
      validateField('idPatrocinador');

      if (state.errors.nome || state.errors.idPatrocinador) {
        return;
      }

      loadingCreate.value = true;

      try {
        await axios.post(`${process.env.VUE_APP_BACKEND_URL}/quadras`, {
          nome: state.nome,
          idPatrocinador: state.idPatrocinador
        });
        alert('Quadra criada com sucesso!');
        state.nome = '';
        state.idPatrocinador = '';
        fetchCourts(); // Atualizar a lista de quadras
      } catch (error) {
        console.error('Erro ao criar quadra:', error);
        alert('Erro ao criar quadra');
      } finally {
        loadingCreate.value = false;
      }
    };

    const fetchPatrocinadores = async () => {
      try {
        const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/patrocinadores`);
        patrocinadores.value = response.data;
      } catch (error) {
        console.error('Erro ao buscar patrocinadores:', error);
      }
    };

    const fetchCourts = async () => {
      try {
        const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/quadras`);
        courts.value = response.data;
      } catch (error) {
        console.error('Erro ao buscar quadras:', error);
      } finally {
        loadingData.value = false;  // Desativa o loader ao carregar os dados
      }
    };

    // Função para abrir o modal de confirmação antes de excluir
    const confirmDeleteCourt = (id: number) => {
      deletingCourtId.value = id;
      showConfirmation.value = true;
    };

    // Função para cancelar exclusão
    const cancelDeleteCourt = () => {
      showConfirmation.value = false;
      deletingCourtId.value = null;
    };

    // Função para confirmar exclusão
    const confirmDelete = async () => {
      if (deletingCourtId.value) {
        loadingDelete.value = true; // Ativar loader de exclusão
        try {
          await axios.delete(`${process.env.VUE_APP_BACKEND_URL}/quadras/${deletingCourtId.value}`);
          alert('Quadra excluída com sucesso!');
          fetchCourts();
        } catch (error) {
          console.error('Erro ao excluir quadra:', error);
          alert('Erro ao excluir quadra');
        } finally {
          loadingDelete.value = false; // Desativar loader de exclusão
          showConfirmation.value = false;
          deletingCourtId.value = null;
        }
      }
    };

    onMounted(() => {
      fetchPatrocinadores();
      fetchCourts();
    });

    return {
      state,
      patrocinadores,
      courts,
      createCourt,
      validateField,
      showConfirmation,
      confirmDeleteCourt,
      cancelDeleteCourt,
      confirmDelete,
      loadingData,
      loadingCreate,
      loadingDelete,
      deletingCourtId
    };
  }
});
</script>

<style scoped>
.create-court-page {
  position: relative;
  width: 100%;
  padding: 60px;
  min-height: 100vh;
  background: linear-gradient(135deg, #FF6A00, #f01f84);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

h1 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #000000;
  font-weight: bold;
}

.form-container {
  background: white;
  padding: 40px;
  border-radius: 10px;
  width: 100%;
  max-width: 500px;
  text-align: center;
  margin-bottom: 30px;
}

.form-group {
  margin-bottom: 20px;
  text-align: left;
}

label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #333;
}

input,
select {
  width: 100%;
  padding: 12px;
  font-size: 1.1rem;
  border-radius: 10px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  color: #333;
}

input:focus,
select:focus {
  border-color: #f857a6;
  outline: none;
}

.error {
  color: red;
  font-size: 0.9rem;
  margin-top: 8px;
}

.button {
  width: 100%;
  padding: 12px;
  font-size: 1.1rem;
  font-weight: bold;
  background-color: #f01f84;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(248, 87, 166, 0.3);
  transition: background-color 0.3s, transform 0.2s;
}

.button:hover {
  background-color: #ff5858;
}

.courts-list {
  width: 100%;
  max-width: 800px;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

h2 {
  color: #f01f84;
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem;
}

table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
}

thead th {
  background-color: #f01f84;
  color: white;
  padding: 10px;
  border-bottom: 2px solid #ff6a00;
}

tbody td {
  padding: 15px;
  border-bottom: 1px solid #ccc;
  text-align: center;
  color: black;
}

tbody tr:hover {
  background-color: #f9f9f9;
}

.icon-trash {
  color: #ff5858;
  cursor: pointer;
}

.icon-trash:hover {
  transform: scale(1.2);
  transition: transform 0.2s;
}

.confirmation-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  color: black;
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  max-width: 500px;
  width: 100%;
}

.modal-content h3 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: black;
}

.modal-content p {
  font-size: 1rem;
  margin-bottom: 30px;
}

.modal-actions {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.btn-cancel,
.btn-confirm {
  padding: 15px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-cancel {
  background-color: #ff4c4c;
  color: white;
}

.btn-confirm {
  background-color: #4300a2 !important;
  color: white;
}

.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

.loader.small {
    width: 40px;
    height: 40px;
  }

.loader-container p {
  font-size: 1.3rem;
  color: #000;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .create-court-page {
    padding: 60px;
  }

  .form-container {
    padding: 20px;
    width: 90%;
  }

  .courts-list {
    padding: 15px;
  }

  h1,
  h2 {
    font-size: 1.5rem;
  }

  .form-group label,
  .form-group input,
  .form-group select {
    font-size: 0.9rem;
  }

  .form-group {
    margin-bottom: 15px;
  }

  .icon-trash {
    font-size: 1.2rem;
  }
}

@media (max-width: 500px){
  table {
    display: block;
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
  }
}

</style>
