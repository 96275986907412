<template>
  <div class="sponsor-container">
    <HomeIcon :showArrow="true" to="adm-dashboard"/>
    <div class="sponsor-box">
      <h1>Criar Patrocinador</h1>
      <!-- Loader para indicar que está enviando os dados -->
      <div v-if="loading" class="loader-container">
        <div class="loader"></div>
        <p>Enviando dados...</p>
      </div>

      <form v-else @submit.prevent="createSponsor">
        <div class="form-group">
          <label for="nome">Nome</label>
          <input
            type="text"
            id="nome"
            v-model="state.nome"
            @blur="validateField('nome')"
          />
          <span v-if="state.errors.nome" class="error">{{ state.errors.nome }}</span>
        </div>

        <div class="form-group">
          <label for="descricao">Descrição</label>
          <textarea
            id="descricao"
            v-model="state.descricao"
            @blur="validateField('descricao')"
          ></textarea>
          <span v-if="state.errors.descricao" class="error">{{ state.errors.descricao }}</span>
        </div>

        <div class="form-group">
          <label for="imagem">Imagem</label>
          <input type="file" id="imagem" @change="onFileChange" />
          <span v-if="state.errors.imagem" class="error">{{ state.errors.imagem }}</span>
        </div>

        <button type="submit" class="button">Criar Patrocinador</button>
      </form>
    </div>

    <!-- Tabela de patrocinadores -->
    <div class="sponsors-list">
      <h2>Patrocinadores Cadastrados</h2>

      <!-- Loader para indicar que os dados do grid estão sendo carregados -->
      <div v-if="loadingGrid" class="loader-container">
        <div class="loader"></div>
        <p>Carregando dados...</p>
      </div>

      <table v-else>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Descrição</th>
            <th>Imagem</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="sponsor in sponsors" :key="sponsor.id">
            <td>{{ sponsor.nome }}</td>
            <td>{{ sponsor.descricao || 'Sem descrição' }}</td>
            <td>
              <img :src="sponsor.imagem" alt="Imagem do Patrocinador" class="thumbnail" />
            </td>
            <td>
              <!-- Botão de deletar com loader -->
              <div v-if="loadingDelete && deletingSponsorId === sponsor.id" class="loader-container">
                <div class="loader small"></div> <!-- Loader pequeno enquanto exclui -->
              </div>
              <div v-else>
                <font-awesome-icon icon="trash" class="icon-trash" @click="confirmDelete(sponsor.id)" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Modal de confirmação para excluir patrocinador -->
    <div v-if="showConfirmation" class="confirmation-modal">
      <div class="modal-content">
        <h3>Confirmar Exclusão</h3>
        <p>Deseja realmente excluir este patrocinador?</p>
        <div class="modal-actions">
          <button @click="cancelDelete" class="btn-cancel">Cancelar</button>
          <button @click="confirmDeleteAction" class="btn-confirm">Confirmar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, ref, onMounted } from 'vue';
import axios from 'axios';
import * as yup from 'yup';
import HomeIcon from '@/components/HomeIcon.vue';

interface Sponsor {
  id: number;
  nome: string;
  descricao: string;
  imagem: string;
}

interface Errors {
  nome?: string;
  descricao?: string;
  imagem?: string;
}

interface State {
  nome: string;
  descricao: string;
  imagem: File | null;
  errors: Errors;
}

export default defineComponent({
  name: 'CreateSponsor',
  components: {
    HomeIcon,
  },
  setup() {
    const state = reactive<State>({
      nome: '',
      descricao: '',
      imagem: null,
      errors: {
        nome: '',
        descricao: '',
        imagem: '',
      },
    });

    const sponsors = ref<Sponsor[]>([]);
    const loading = ref(false);
    const loadingDelete = ref(false); // Loader para exclusão
    const loadingGrid = ref(true); // Loader para o carregamento dos dados do grid
    const showConfirmation = ref(false);
    const deletingSponsorId = ref<number | null>(null);

    const schema = yup.object().shape({
      nome: yup.string().required('Nome é obrigatório'),
      descricao: yup.string().required('Descrição é obrigatória'),
      imagem: yup.mixed().required('Imagem é obrigatória'),
    });

    const fetchSponsors = async () => {
      loadingGrid.value = true; // Ativar o loader antes de buscar os dados
      try {
        const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/patrocinadores`);
        sponsors.value = response.data;
      } catch (error) {
        console.error('Erro ao buscar patrocinadores:', error);
      } finally {
        loadingGrid.value = false; // Desativar o loader após os dados serem carregados
      }
    };

    const validateField = async (field: keyof State) => {
        await schema.validateAt(field, state);
    };

    const onFileChange = (event: Event) => {
      const input = event.target as HTMLInputElement;
      if (input.files && input.files.length > 0) {
        state.imagem = input.files[0];
      }
    };

    const createSponsor = async () => {
      try {
        await schema.validate(state, { abortEarly: false });

        loading.value = true;

        const formData = new FormData();
        formData.append('file', state.imagem as File);
        formData.append('upload_preset', 'akehgolx');

        const uploadResponse = await axios.post(
          'https://api.cloudinary.com/v1_1/dw2pcztyy/image/upload',
          formData
        );

        const imageUrl = uploadResponse.data.secure_url;

        await axios.post(`${process.env.VUE_APP_BACKEND_URL}/patrocinadores`, {
          nome: state.nome,
          descricao: state.descricao,
          imagem: imageUrl,
        });

        alert('Patrocinador criado com sucesso!');
        state.nome = '';
        state.descricao = '';
        state.imagem = null;
        fetchSponsors();
      } catch (err) {
        if (err instanceof yup.ValidationError) {
          const newErrors: Errors = {};
          err.inner.forEach((error) => {
            if (error.path) {
              newErrors[error.path as keyof Errors] = error.message;
            }
          });
          state.errors = newErrors; // Atualiza os erros corretamente
        } else {
          console.error('Erro ao criar patrocinador:', err);
        }
      } finally {
        loading.value = false;
      }
    };

    const confirmDelete = (id: number) => {
      deletingSponsorId.value = id;
      showConfirmation.value = true;
    };

    const cancelDelete = () => {
      showConfirmation.value = false;
      deletingSponsorId.value = null;
    };

    const confirmDeleteAction = async () => {
      if (!deletingSponsorId.value) return;
      loadingDelete.value = true; // Ativar o loader de exclusão
      try {
        await axios.delete(`${process.env.VUE_APP_BACKEND_URL}/patrocinadores/${deletingSponsorId.value}`);
        alert('Patrocinador excluído com sucesso!');
        fetchSponsors();
      } catch (error) {
        console.error('Erro ao excluir patrocinador:', error);
        alert('Erro ao excluir patrocinador');
      } finally {
        cancelDelete();
        loadingDelete.value = false; // Desativar o loader de exclusão
      }
    };

    onMounted(() => {
      fetchSponsors();
    });

    return {
      state,
      sponsors,
      createSponsor,
      onFileChange,
      validateField,
      loading,
      loadingDelete,
      loadingGrid,
      showConfirmation,
      confirmDelete,
      cancelDelete,
      confirmDeleteAction,
      deletingSponsorId,
    };
  },
});
</script>
  
  <style scoped>
  .sponsor-container {
    position: relative;
    width: 100%;
    padding: 60px;
    min-height: 100vh;
    background: linear-gradient(135deg, #FF6A00, #f01f84);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
  }
  
  .sponsor-box {
    background: white;
    padding: 40px;
    border-radius: 10px;
    width: 100%;
    max-width: 500px;
    text-align: center;
    margin-bottom: 30px;
  }
  
  h1 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #000000;
    font-weight: bold;
  }
  
  .form-group {
    margin-bottom: 20px;
    text-align: left;
  }
  
  label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: #333;
  }
  
  input,
  textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 1rem;
    color: #333;
    transition: border-color 0.3s ease;
  }
  
  input:focus,
  textarea:focus {
    border-color: #f857a6;
    outline: none;
  }
  
  textarea {
    resize: vertical;
  }
  
  .error {
    color: red;
    font-size: 0.875rem;
    margin-top: 5px;
  }
  
  .button {
    width: 100%;
    padding: 12px;
    font-size: 1.1rem;
    font-weight: bold;
    background-color: #f01f84;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 0 2px 10px rgba(248, 87, 166, 0.3);
    transition: background-color 0.3s, transform 0.2s;
  }
  
  .button:hover {
    background-color: #ff5858;
  }
  
  /* Loader Centralizado */
  .loader-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px;
  }
  
  .loader {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }
  
  .loader.small {
    width: 40px;
    height: 40px;
  }
  
  .loader-container p {
    font-size: 1.3rem;
    color: #000;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .sponsors-list {
    width: 100%;
    max-width: 800px;
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  h2 {
    color: #f01f84;
    text-align: center;
    margin-bottom: 20px;
    font-size: 2rem;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    background-color: white;
  }
  
  thead th {
    background-color: #f01f84;
    color: white;
    padding: 10px;
    border-bottom: 2px solid #ff6a00;
  }
  
  tbody td {
    padding: 15px;
    border-bottom: 1px solid #ccc;
    text-align: center;
    color: black;
  }
  
  tbody tr:hover {
    background-color: #f9f9f9;
  }
  
  .thumbnail {
    width: 50px;
    height: 50px;
    object-fit: cover;
  }
  
  .icon-trash {
    color: #ff5858;
    cursor: pointer;
  }
  
  .icon-trash:hover {
    transform: scale(1.2);
    transition: transform 0.2s;
  }
  
  .confirmation-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    color: black;
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    text-align: center;
    max-width: 500px;
    width: 100%;
  }
  
  .modal-content h3 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: black;
  }
  
  .modal-content p {
    font-size: 1rem;
    margin-bottom: 30px;
  }
  
  .modal-actions {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .btn-cancel,
  .btn-confirm {
    padding: 15px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .btn-cancel {
    background-color: #ff4c4c;
    color: white;
  }
  
  .btn-confirm {
    background-color: #4300a2 !important;
    color: white;
  }

  /* Ajuste geral para dispositivos médios (tablets) */
@media (max-width: 768px) {
  .sponsor-container {
    padding: 60px;
  }

  .sponsor-box {
    padding: 20px;
    width: 90%;
  }

  .sponsors-list {
    padding: 15px;
  }

  h1,
  h2 {
    font-size: 1.5rem;
  }

  .form-group label,
  .form-group input,
  .form-group textarea {
    font-size: 0.9rem;
  }

  .form-group {
    margin-bottom: 15px;
  }

  .modal-content {
    width: 90%;
    padding: 20px;
  }

  .thumbnail {
    width: 40px;
    height: 40px;
  }

  table {
    display: block;
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
  }
}
  </style>
  