import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0fbba7da"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "app",
  class: "container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_ChatBot = _resolveComponent("ChatBot")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_view),
    (_ctx.showChatBot)
      ? (_openBlock(), _createBlock(_component_ChatBot, { key: 0 }))
      : _createCommentVNode("", true)
  ]))
}