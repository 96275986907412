<template>
  <div>
    <HomeIcon/>
  <div class="reset-password-container">
    <div v-if="!tokenExpired">
      <form @submit.prevent="resetPassword">
        <div class="form-group">
          <h1>Redefinir Senha</h1>
          <div v-if="loading" class="loader-container">
            <div class="loader"></div>
            <p>Redefinindo senha...</p>
          </div>
          <div v-else>
            <label for="newPassword">Nova Senha</label>
            <input type="password" id="newPassword" v-model="newPassword" required />
            <button type="submit">Definir nova senha</button>
          </div>
        </div>
      </form>
    </div>
    <div v-else class="token-expired">
      <p>O token expirou. Por favor, solicite uma nova redefinição de senha.</p>
      <router-link class="back-to-login" to="/login">Voltar para o login</router-link>
    </div>
  </div>
</div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router';
import jwtDecode from 'jwt-decode';
import HomeIcon from '../../components/HomeIcon.vue';

export default defineComponent({
  name: 'ResetPassword',
  components: {
    HomeIcon,
  },
  setup() {
    const newPassword = ref('');
    const loading = ref(false);
    const tokenExpired = ref(false);
    const token = ref('');
    const router = useRouter();
    const route = useRoute();

    const resetPassword = async () => {
      loading.value = true;
      try {
        await axios.post(`${process.env.VUE_APP_BACKEND_URL}/reset-password`, {
          token: token.value,
          newPassword: newPassword.value,
        });
        alert('Senha redefinida com sucesso!');
        router.push('/login'); 
      } catch (error) {
        console.error('Erro ao redefinir a senha:', error);
        alert('Erro ao redefinir a senha. Por favor, tente novamente.');
      } finally {
        loading.value = false;
      }
    };

    const verifyToken = () => {
      token.value = (route.params.token as string) || '';

      if (!token.value) {
        tokenExpired.value = true;
        return;
      }

      try {
        const decoded: { exp: number } = jwtDecode(token.value);
        const currentTime = Math.floor(Date.now() / 1000);
        if (decoded.exp < currentTime) {
          tokenExpired.value = true;
        }
      } catch (error) {
        console.error('Token inválido:', error);
        tokenExpired.value = true;
      }
    };

    onMounted(() => {
      verifyToken();
    });

    return {
      newPassword,
      resetPassword,
      loading,
      tokenExpired,
    };
  },
});
</script>

<style scoped>
.reset-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #FF8C00 0%, #FF4081 100%);
  color: white;
  padding: 40px 20px;
}

.reset-password-container h1 {
  text-transform: uppercase;
  font-size: 2rem;
  text-align: center;
  color: #FF4081;
  margin-bottom: 1rem;
}

form {
  background: white;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 100%;
  max-width: 400px;
}

.form-group {
  margin-bottom: 1rem;
  text-align: left;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 700;
}

input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  margin-bottom: 1rem;
}

button {
text-transform: uppercase;
  background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
  color: white;
  border: none;
  padding: 0.75rem;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
  font-weight: bold;
}

button:hover:not(:disabled) {
  background: linear-gradient(135deg, #f01f84 0%, #FF6A00 100%);
}

/* Loader Centralizado */
.loader-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #FF4081;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 1.5s linear infinite;
  margin-bottom: 10px;
}

.loader-container p {
  font-size: 1.2rem;
  color: #333;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.token-expired {
  background: white;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 100%;
  max-width: 400px;
  color: #333;
}

.token-expired p {
  margin-bottom: 1rem;
  font-size: 1.1rem;
}

.back-to-login {
  display: inline-block;
  padding: 10px 20px;
  background-color: #FF4081;
  color: white;
  border-radius: 5px;
  font-weight: bold;
  text-decoration: none;
  transition: background-color 0.3s;
}

.back-to-login:hover {
  background-color: #FF4081CC;
}

@media (max-width: 768px) {
  .reset-password-container {
    padding: 20px;
  }

  form,
  .token-expired {
    padding: 30px;
    width: 90%;
  }

  h1 {
    font-size: 1.6rem;
  }

  input {
    font-size: 0.9rem;
    padding: 0.6rem;
  }

  button {
    padding: 0.6rem;
    font-size: 1rem;
  }

  .loader-container p {
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  .reset-password-container {
    padding: 15px;
  }

  form,
  .token-expired {
    padding: 20px;
    width: 100%;
  }

  h1 {
    font-size: 1.4rem;
  }

  input {
    font-size: 0.85rem;
    padding: 0.5rem;
  }

  button {
    padding: 0.5rem;
    font-size: 0.9rem;
  }

  .loader {
    width: 80px;
    height: 80px;
    border-width: 6px;
  }

  .loader-container p {
    font-size: 0.9rem;
  }
}
</style>
