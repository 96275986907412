<template>
  <div class="success-page">
    <div v-if="loading">
      <div class="thinking-icon">🤔</div>
      <h1>Verificando o status do pagamento...</h1>
    </div>
    <div v-else>
      <h1>{{ message }}</h1>
      <p v-if="paymentSuccessful">Sua reserva foi confirmada.</p>
      <p v-else-if="paymentAlreadyProcessed">O pagamento já foi processado anteriormente.</p>
      <p v-else-if="paymentRefunded">O pagamento foi reembolsado devido a um conflito de horário.</p>
      <p v-else-if="paymentAlreadyRefunded">O pagamento já foi reembolsado anteriormente.</p>
      <p v-else>Se ocorreu algum problema com sua reserva, entre em contato com o suporte.</p>
      <button @click="goToHome">Voltar para a Home</button>
    </div>
    <!-- Adicionando o contêiner de folhas -->
    <div class="leaves">
      <div class="leaf"></div>
      <div class="leaf"></div>
      <div class="leaf"></div>
      <div class="leaf"></div>
      <div class="leaf"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';

export default defineComponent({
  name: 'SuccessRenovacaoPage',
  setup() {
    const router = useRouter();
    const paymentSuccessful = ref(false);
    const paymentAlreadyProcessed = ref(false);
    const paymentAlreadyRefunded = ref(false);
    const paymentRefunded = ref(false);
    const invalid = ref(false);
    const message = ref('Verificando o status do pagamento...');
    const loading = ref(true);

    const checkPaymentStatus = async (sessionId: string) => {
      try {
        const response = await axios.post(`${process.env.VUE_APP_BACKEND_URL}/stripe/confirm-payment-renovacao`, { sessionId });
        const { status, conflict } = response.data;

        if (status === 'invalid') {
          message.value = 'Dados inválidos';
          invalid.value = true;
        } else if (status === 'refunded') {
          paymentAlreadyRefunded.value = true;
          message.value = 'Pagamento já foi reembolsado.';
        } else if (status === 'alreadyProcessed') {
          paymentAlreadyProcessed.value = true;
          message.value = 'Este pagamento já foi processado anteriormente.';
        } else if (status === 'success') {
          paymentSuccessful.value = true;
          message.value = 'Pagamento confirmado com sucesso.';
        } else if (conflict === true) {
          paymentRefunded.value = true;
          message.value = 'Pagamento Reembolsado.';
        } else {
          message.value = 'O pagamento não foi confirmado.';
        }
      } catch (error) {
        console.error('Erro ao verificar status do pagamento:', error);
        message.value = 'Erro.';
      } finally {
        loading.value = false;
      }
    };

    onMounted(() => {
      const sessionId = new URLSearchParams(window.location.search).get('session_id');
      if (sessionId) {
        checkPaymentStatus(sessionId);
      } else {
        message.value = 'ID da sessão de pagamento não encontrado.';
        loading.value = false;
      }
    });

    const goToHome = () => {
      router.push('/');
    };

    return {
      paymentSuccessful,
      paymentAlreadyProcessed,
      paymentAlreadyRefunded,
      paymentRefunded,
      message,
      loading,
      invalid,
      goToHome,
    };
  },
});
</script>

<style scoped>
.success-page {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
  color: #ffffff;
  text-align: center;
  padding: 20px;
  overflow: hidden; /* Importante para o efeito de folhas */
}

.thinking-icon {
  font-size: 5rem;
  margin-bottom: 20px;
  text-shadow: 
    -4px -4px 0 #fff,  
    4px -4px 0 #fff,
    -4px 4px 0 #fff,
    4px 4px 0 #fff;
  animation: thinking 1.5s infinite alternate ease-in-out;
}

/* Animação das folhas voando */
.leaves {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1; /* Para que fique no fundo */
}

.leaf {
  position: absolute;
  bottom: 100%;
  width: 15px;
  height: 15px;
  background-color: white;
  border-radius: 50%;
  opacity: 0.7;
  animation: float 8s ease-in infinite, sway 3s ease-in-out infinite alternate;
}

.leaf:nth-child(1) {
  left: 10%;
  animation-duration: 7s;
  animation-delay: 1s;
  width: 12px;
  height: 12px;
}

.leaf:nth-child(2) {
  left: 25%;
  animation-duration: 6s;
  animation-delay: 0.5s;
  width: 10px;
  height: 10px;
}

.leaf:nth-child(3) {
  left: 50%;
  animation-duration: 8s;
  animation-delay: 2s;
  width: 15px;
  height: 15px;
}

.leaf:nth-child(4) {
  left: 70%;
  animation-duration: 9s;
  animation-delay: 0.3s;
  width: 14px;
  height: 14px;
}

.leaf:nth-child(5) {
  left: 90%;
  animation-duration: 10s;
  animation-delay: 1.5s;
  width: 13px;
  height: 13px;
}

@keyframes float {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 0.8;
  }
  100% {
    transform: translateY(100vh) rotate(360deg);
    opacity: 0;
  }
}

@keyframes sway {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(20px);
  }
}

h1 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #ffffff;
}

p {
  font-size: 1.25rem;
  margin: 10px 0;
  color: #ffffff;
}

button {
  padding: 15px 30px;
  background-color: #ffffff;
  color: #ff6a00;
  font-weight: bold;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1.1rem;
  transition: all 0.3s;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  margin-top: 20px;
}

button:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
  h1 {
    font-size: 1.8rem;
  }

  p {
    font-size: 1rem;
  }

  button {
    font-size: 1rem;
    padding: 12px 25px;
  }
}
</style>
