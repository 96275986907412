<template>
  <div class="dashboard">
    <HomeIcon :showArrow="true" to="/account" />
    <h2>Painel de Controle de Funcionários</h2>
    <div class="button-group">
      <router-link to="/employee-horarios" class="dashboard-button">Ver Horários Ocupados</router-link>
      <router-link to="/planos" class="dashboard-button">Ver Planos Ativos</router-link>
      <router-link to="/employee-searchClients" class="dashboard-button">Buscar Usuário</router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import HomeIcon from '../../components/HomeIcon.vue';

export default defineComponent({
  name: 'EmployeeDashboard',
  components: {
    HomeIcon
  }
});
</script>

<style scoped>
.dashboard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  height: 100vh;
  text-align: center;
  background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
}

h2 {
  font-size: 2rem;
  color: white;
  margin-bottom: 30px;
  text-transform: uppercase;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}

.button-group {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.dashboard-button {
  background: white;
  padding: 15px 30px;
  font-size: 1.2rem;
  color: #f01f84;
  text-decoration: none;
  border-radius: 8px;
  font-weight: bold;
  transition: background 0.3s, transform 0.3s;
  text-transform: uppercase;
}

.dashboard-button:hover {
  transform: scale(1.05);
}
</style>
