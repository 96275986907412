<template>
  <div>
    <Header />
    <div class="schedule-page">
      <div class="overlay">
        <h1>Agendamento de Aulas</h1>
        <h2>Escolha seu professor e agende sua aula via WhatsApp</h2>
        <div v-if="loading" class="loader-container">
          <div class="loader"></div>
          <p>Carregando dados...</p>
        </div>
        <div v-else class="teachers">
          <div v-for="teacher in teachers" :key="teacher.id" class="teacher">
            <h3>{{ teacher.nome }}</h3>
            <h4>{{ teacher.especialidade }}</h4>
            <p>{{ teacher.descricao }}</p>
            <a :href="`https://wa.me/55${teacher.telefone}?text=Olá,%20gostaria%20de%20agendar%20uma%20aula%20de%20${teacher.especialidade}`" target="_blank">
              <button>AGENDAR AULA VIA WHATSAPP</button>
            </a>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import axios from "axios";
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";

interface Teacher {
  id: number;
  nome: string;
  especialidade: string;
  descricao: string;
  telefone: string;
}

export default defineComponent({
  name: "SchedulePage",
  components: {
    Header,
    Footer,
  },
  setup() {
    const teachers = ref<Teacher[]>([]);
    const loading = ref(true);

    const fetchTeachers = async () => {
  try {
    const response = await axios.get<Teacher[]>(`${process.env.VUE_APP_BACKEND_URL}/professor`);
    teachers.value = response.data;
  } catch (error) {
    console.error("Erro ao buscar professores:", error);
  } finally {
    loading.value = false; // Isso garante que o loader seja ocultado após a chamada.
  }
};


    onMounted(() => {
      fetchTeachers();
    });

    return {
      teachers,
      loading,
    };
  },
});
</script>

<style scoped>

.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

.loader-container p {
  color: white;
  margin-top: 20px;
  font-size: 1.5em;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.schedule-page {
  position: relative;
  width: 100%;
  background: url('@/assets/quadras.jpg') no-repeat center center fixed;
  background-size: cover;
  display: flex;
  flex-direction: column;
}

.overlay {
  position: relative;
  width: 100%;
  padding: 40px 20px;
  min-height: 70vh;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

h1, h2 {
  color: white;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}

h1 {
  font-size: 2.5em;
  margin-bottom: 10px;
  text-align: center;
  text-transform: uppercase;
}

h2 {
  font-size: 1.5em;
  margin-bottom: 40px;
  text-align: center;
}

.teachers {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  justify-content: center;
  max-width: 900px;
  width: 100%;
}

.teacher {
  background: linear-gradient(to top, #f01f84, #FF6A00);
  color: white;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
  text-align: left;
  width: 300px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.teacher:hover {
  transform: translateY(-5px);
}

.teacher h3 {
  margin-bottom: 5px;
  font-size: 1.8em;
}

.teacher h4 {
  margin-bottom: 10px;
  font-size: 1.2em;
  font-weight: 400;
  color: white;
}

.teacher p {
  margin-bottom: 12px;
  font-size: 1.1em;
}

/* Botão */
button {
  margin-top: 15px;
  padding: 12px 25px;
  background: white;
  color: #f01f84;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2em;
  font-weight: bold;
  transition: background 0.3s, transform 0.3s;
}

button:hover {
  transform: scale(1.05);
}
</style>
