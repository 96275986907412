<template>
  <div class="user-registration-page" @click="handleOutsideClick">
    <HomeIcon :showArrow="true" to="adm-dashboard"/>
    
    <!-- Seção para criar novo usuário -->
    <div class="create-user-section">
      <h3>Criar Novo Usuário</h3>
      <form @submit.prevent="createUser">
        <div class="form-group">
          <label for="nome">Nome</label>
          <input
            type="text"
            id="nome"
            v-model="newUser.nome"
            placeholder="Digite o nome"
            @input="validateField('nome', newUser)"
          />
          <span v-if="newUser.errors?.nome" class="error">{{ newUser.errors?.nome }}</span>
        </div>

        <div class="form-group">
          <label for="email">Email</label>
          <input
            type="email"
            id="email"
            v-model="newUser.email"
            placeholder="Digite o email"
            @input="validateField('email', newUser)"
          />
          <span v-if="newUser.errors?.email" class="error">{{ newUser.errors?.email }}</span>
        </div>

        <div class="form-group">
          <label for="telefone">Telefone</label>
          <input
            type="tel"
            id="telefone"
            v-model="newUser.telefone"
            placeholder="Digite o telefone"
            @input="validateField('telefone', newUser); applyPhoneMask($event, newUser)"
          />
          <span v-if="newUser.errors?.telefone" class="error">{{ newUser.errors?.telefone }}</span>
        </div>

        <div class="form-group">
          <label for="cpf">CPF</label>
          <input
            type="text"
            id="cpf"
            v-model="newUser.cpf"
            placeholder="Digite o CPF"
            @input="validateField('cpf', newUser); applyCpfMask($event, newUser)"
          />
          <span v-if="newUser.errors?.cpf" class="error">{{ newUser.errors?.cpf }}</span>
        </div>

        <div class="form-group senha-group">
          <label for="senha">Senha</label>
          <div class="senha-wrapper">
            <input
              :type="mostrarSenha ? 'text' : 'password'"
              id="senha"
              v-model="newUser.senha"
              placeholder="Digite a senha"
              @input="validateField('senha', newUser)"
            />
            <button type="button" class="toggle-password" @click="mostrarSenha = !mostrarSenha">
              <font-awesome-icon :icon="mostrarSenha ? 'eye-slash' : 'eye'" />
            </button>
          </div>
          <span v-if="newUser.errors?.senha" class="error">{{ newUser.errors?.senha }}</span>
        </div>

        <button type="submit" class="button create-button">Criar Usuário</button>
      </form>
    </div>

    <!-- Seção para buscar e editar usuário existente -->
    <div class="search-user-section">
      <h3>Buscar Usuário Existente</h3>
      <div class="search-section">
        <input
          v-model="searchQuery"
          @input="onInput"
          @focus="handleFocus"
          placeholder="Digite o nome ou email do usuário"
          class="search-input"
          :disabled="isEditing"
        />

        <div v-if="loading" class="loader-container">
          <div class="loader"></div>
          <p>Carregando dados...</p>
        </div>

        <ul v-if="!loading && showDropdown && filteredUsers.length > 0" class="user-dropdown">
          <li v-for="user in filteredUsers" :key="user.id" @click.stop="selectUser(user)">
            {{ user.nome }} - {{ user.email }}
          </li>
        </ul>

        <p v-if="!loading && searchQuery.trim().length > 0 && filteredUsers.length === 0" class="no-results">
          Nenhum usuário encontrado.
        </p>
      </div>

      <div v-if="selectedUser" class="user-details">
        <div class="header">
          <h4>Dados do Usuário: </h4>
          <button v-if="isEditing" type="button" @click="cancelEdit" class="cancel-x">✕</button>
        </div>

        <form @submit.prevent="saveUser">
          <div class="form-group">
            <label for="nome">Nome</label>
            <input type="text" id="nome" v-model="selectedUser.nome" :disabled="!isEditing" />
            <span v-if="selectedUser.errors?.nome" class="error">{{ selectedUser.errors?.nome }}</span>
          </div>

          <div class="form-group">
            <label for="email">Email</label>
            <input type="email" id="email" v-model="selectedUser.email" :disabled="!isEditing" />
            <span v-if="selectedUser.errors?.email" class="error">{{ selectedUser.errors?.email }}</span>
          </div>

          <div class="form-group">
            <label for="telefone">Telefone</label>
            <input
              type="tel"
              id="telefone"
              v-model="selectedUser.telefone"
              @input="applyPhoneMask($event, selectedUser)"
              :disabled="!isEditing"
            />
            <span v-if="selectedUser.errors?.telefone" class="error">{{ selectedUser.errors?.telefone }}</span>
          </div>

          <div class="form-group">
            <label for="cpf">CPF</label>
            <input
              type="text"
              id="cpf"
              v-model="selectedUser.cpf"
              @input="applyCpfMask($event, selectedUser)"
              :disabled="!isEditing"
            />
            <span v-if="selectedUser.errors?.cpf" class="error">{{ selectedUser.errors?.cpf }}</span>
          </div>

          <div v-if="isEditing" class="edit-actions">
            <button type="submit" class="
button save-button">Salvar</button>
          </div>

          <div v-else>
            <button type="button" @click="editUser" class="button edit-button">Editar</button>
            <button type="button" @click="deleteUser" class="button delete-button">Excluir</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue';
import * as yup from 'yup';
import axios from 'axios';
import HomeIcon from '@/components/HomeIcon.vue';
import { validateCPF } from '@/services/validateCPF';

export default defineComponent({
  name: 'UserRegistrationPage',
  components: {
    HomeIcon,
  },
  setup() {
    const newUser = ref({
      id: '',
      nome: '',
      email: '',
      telefone: '',
      cpf: '',
      senha: '',
      errors: {} as Record<string, string>,
    });

    const mostrarSenha = ref(false);
    const mostrarSenhaEdit = ref(false);
    const searchQuery = ref<string>('');
    const users = ref<any[]>([]);
    const selectedUser = ref<any | null>(null);
    const isEditing = ref(false);
    const showDropdown = ref(false);
    const loading = ref(false);
    const loadingAction = ref(false);

    const userSchema = yup.object().shape({
      email: yup.string().email('Email inválido').required('Email é obrigatório'),
      nome: yup.string().min(2, 'O nome deve ter pelo menos 2 caracteres').required('Nome é obrigatório'),
      cpf: yup.string().required('CPF é obrigatório').test('is-valid-cpf', 'CPF inválido', (value: any) => {
        const cleanCpf = value?.replace(/\D/g, '');
        return cleanCpf && cleanCpf.length === 11 && validateCPF(cleanCpf);
      }),
      telefone: yup.string().matches(/^\(\d{2}\) \d{4,5}-\d{4}$/, 'Formato: (XX) XXXXX-XXXX').required('Telefone é obrigatório'),
      senha: yup.string().min(8, 'A senha deve ter pelo menos 8 caracteres').required('Senha é obrigatória'),
    });

    const validateField = (field: keyof typeof newUser.value, userObj: any) => {
      userSchema.validateAt(field, userObj).then(() => (userObj.errors[field] = '')).catch((err) => (userObj.errors[field] = err.message));
    };

    const createUser = async () => {
      try {
        loadingAction.value = true;
        await userSchema.validate(newUser.value, { abortEarly: false });
        await axios.post(`${process.env.VUE_APP_BACKEND_URL}/users`, newUser.value);
        alert('Usuário criado com sucesso!');
        newUser.value = { id: '', nome: '', email: '', telefone: '', cpf: '', senha: '', errors: {} };
      } catch (error) {
        if (error instanceof yup.ValidationError) {
          error.inner.forEach((err) => {
            if (err.path) {
              newUser.value.errors[err.path] = err.message;
            }
          });
        }
      } finally {
        loadingAction.value = false;
      }
    };

    const onInput = async () => {
      if (searchQuery.value.trim() === '') {
        users.value = [];
        showDropdown.value = false;
        return;
      }

      loading.value = true;
      showDropdown.value = true;

      try {
        const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/searchClients?query=${searchQuery.value}`);
        users.value = response.data;
        showDropdown.value = users.value.length > 0;
      } catch (error) {
        console.error('Erro ao buscar usuários:', error);
      } finally {
        loading.value = false;
      }
    };

    const filteredUsers = computed(() => {
      const query = searchQuery.value.toLowerCase().trim();
      return users.value.filter((client) =>
        client.nome.toLowerCase().includes(query) || client.email.toLowerCase().includes(query)
      );
    });

    const selectUser = (user: any) => {
      selectedUser.value = { ...user, errors: {} };
      searchQuery.value = '';
      users.value = [];
      showDropdown.value = false;
    };

    const editUser = () => {
      isEditing.value = true;
    };

    const cancelEdit = () => {
      isEditing.value = false;
    };

    const saveUser = async () => {
      try {
        loadingAction.value = true;
        await userSchema.validate(selectedUser.value, { abortEarly: false });
        await axios.put(`${process.env.VUE_APP_BACKEND_URL}/users/${selectedUser.value.id}`, selectedUser.value);
        alert('Usuário atualizado com sucesso!');
        isEditing.value = false;
      } catch (error) {
        console.error('Erro ao salvar o usuário:', error);
      } finally {
        loadingAction.value = false;
      }
    };

    const deleteUser = async () => {
      if (confirm('Tem certeza que deseja excluir este usuário?')) {
        try {
          await axios.delete(`${process.env.VUE_APP_BACKEND_URL}/users/${selectedUser.value.id}`);
          alert('Usuário excluído com sucesso!');
          selectedUser.value = null;
        } catch (error) {
          console.error('Erro ao excluir usuário:', error);
        }
      }
    };

    const applyCpfMask = (event: Event, userObj: any) => {
      const input = event.target as HTMLInputElement;
      let value = input.value.replace(/\D/g, '');
      if (value.length > 11) value = value.slice(0, 11);
      const cpfParts = value.match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/);
      if (cpfParts) {
        input.value = !cpfParts[2] ? cpfParts[1] : `${cpfParts[1]}.${cpfParts[2]}${cpfParts[3] ? '.' + cpfParts[3] : ''}${cpfParts[4] ? '-' + cpfParts[4] : ''}`;
        userObj.cpf = input.value;
      }
    };

    const applyPhoneMask = (event: Event, userObj: any) => {
      const input = event.target as HTMLInputElement;
      let value = input.value.replace(/\D/g, '');
      if (value.length > 11) value = value.slice(0, 11);
      const phoneParts = value.match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
      if (phoneParts) {
        input.value = !phoneParts[2] ? phoneParts[1] : `(${phoneParts[1]}) ${phoneParts[2]}${phoneParts[3] ? '-' + phoneParts[3] : ''}`;
        userObj.telefone = input.value;
      }
    };

    const handleOutsideClick = (event: Event) => {
      const target = event.target as HTMLElement;
      const dropdown = document.querySelector('.user-dropdown');
      const searchInput = document.querySelector('.search-input');

      if (dropdown && searchInput && !dropdown.contains(target) && !searchInput.contains(target)) {
        showDropdown.value = false;
      }
    };

    const handleFocus = () => {
      showDropdown.value = true;
    };

    return {
      handleOutsideClick,
      handleFocus,
      newUser,
      searchQuery,
      users,
      filteredUsers,
      selectedUser,
      isEditing,
      showDropdown,
      loading,
      mostrarSenha,
      mostrarSenhaEdit,
      validateField,
      createUser,
      onInput,
      selectUser,
      editUser,
      cancelEdit,
      saveUser,
      deleteUser,
      applyCpfMask,
      applyPhoneMask,
    };
  },
});
</script>

<style scoped>
.user-registration-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  min-height: 100vh;
  background: linear-gradient(135deg, #FF6A00, #f01f84);
}

.create-user-section,
.search-user-section {
  margin-top: 10px;
  background: white;
  padding: 40px;
  border-radius: 10px;
  width: 100%;
  max-width: 500px;
  text-align: center;
  position: relative;
  margin-bottom: 30px;
}

.search-user-section h3{
  margin-bottom: 10px;
}

.search-section {
  position: relative;
  margin-bottom: 20px;
}

.search-input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
}

.user-dropdown {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  max-height: 250px;
  overflow-y: auto;
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 0;
  margin: 5px 0 0;
  z-index: 10;
  list-style: none;
}

.user-dropdown li {
  padding: 10px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.user-dropdown li:hover {
  background: #f0f0f0;
}

.no-results {
  margin-top: 10px;
  color: #888;
}

.user-details {
  background: white;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.cancel-x {
  position: absolute;
  top: 0;
  right: -175px;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: red;
  cursor: pointer;
  padding: 0;
  transition: transform 0.3s;
}

.cancel-x:hover {
  transform: scale(1.2);
}

.form-group {
  margin-bottom: 20px;
  text-align: left;
}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
}

input[disabled] {
  background-color: #f9f9f9;
  cursor: not-allowed;
}

button {
  padding: 10px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.create-button {
  background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.create-button:hover{
  background: linear-gradient(135deg, #f01f84 0%, #FF6A00 100%) !important;
  opacity: 100%;
}

.edit-button {
  background-color: #3498db;
  color: white;
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.delete-button {
  background-color: #e74c3c;
  margin-top: 10px;
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.save-button {
  background-color: #2ecc71;
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}

button:hover {
  opacity: 0.9;
}

.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader {
  margin-top: 10px;
  margin-bottom: 5px;
  width: 50px;
  height: 50px;
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #3498db;
  animation: spin 1s linear infinite;
}

.loader-container p {
  font-size: 1.2rem;
  color: #000;
  text-align: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

h4 {
  font-weight: 600;
  margin-bottom: 10px;
}

.edit-actions {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.mostrar-senha {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
}

.error {
  color: red;
  font-size: 0.875rem;
  margin-top: 5px;
}

@media (max-width: 700px){
  .user-registration-page{
    padding: 45px;
  }
}

@media (max-width: 520px){
  .cancel-x {
  right: -160px;
}
}

@media (max-width: 520px){
  .cancel-x {
  right: -145px;
}

@media (max-width: 445px){
  .cancel-x {
  right: -130px;
}
}

@media (max-width: 415px){
  .cancel-x {
  right: -115px;
}
}

@media (max-width: 384px){
  .cancel-x {
  right: -100px;
}
}
}

</style>