<template>
  <div class="admin-clients-page" @click="handleOutsideClick">
    <HomeIcon :showArrow="true" to="adm-dashboard" />
    <div class="container">
      <h3>Gerenciar Usuários</h3>
      <div class="search-section">
        <input
          v-model="searchQuery"
          @input="onInput"
          @focus="showDropdown = true"
          placeholder="Digite o nome ou email do usuário"
          class="search-input"
        />

        <!-- Loader para a busca de clientes -->
        <div v-if="loadingClients" class="loader-container">
          <div class="loader"></div>
          <p>Carregando dados...</p>
        </div>

        <!-- Dropdown de Clientes -->
        <ul
          v-if="showDropdown && !loadingClients && filteredClients.length > 0"
          class="client-dropdown"
        >
          <li
            v-for="client in filteredClients"
            :key="client.id"
            @click.stop="selectClient(client)"
          >
            {{ client.nome }} - {{ client.email }}
          </li>
        </ul>

        <!-- Mensagem de "Nenhum cliente encontrado" -->
        <p
          v-if="!loadingClients && searchQuery.trim().length > 0 && filteredClients.length === 0"
          class="no-results"
        >
          Nenhum cliente encontrado.
        </p>
      </div>

      <!-- Loader para os detalhes do cliente -->
      <div v-if="loadingDetails" class="loader-container">
        <div class="loader"></div>
        <p>Carregando dados...</p>
      </div>

      <!-- Detalhes do cliente -->
      <div v-if="selectedClient && !loadingDetails" class="client-details">
        <h4>Detalhes do Usuário: {{ selectedClient.nome }}</h4>
        <p><strong>Email:</strong> {{ selectedClient.email }}</p>
        <p><strong>Telefone:</strong> {{ selectedClient.telefone }}</p>
        <p><strong>CPF:</strong> {{ selectedClient.cpf }}</p>

        <!-- Seção de Planos Ativos -->
        <div class="client-section">
          <h5>Planos Ativos:</h5>
          <ul v-if="filteredPlanos && filteredPlanos.length > 0">
            <li v-for="plano in filteredPlanos" :key="plano.id" class="plano-item">
              <div class="plano-info">
                <strong>Plano {{ plano.id }}</strong>
                <div class="plano-nome">
                  <strong>{{ plano.plano.nome }}</strong>:
                  {{ formatDate(plano.dataInicio) }} - {{ formatDate(plano.dataFim) }}
                </div>
                <div class="plano-details">
                  <div><strong>Quadra:</strong> {{ plano.quadra.nome }}</div>
                  <div><strong>Dia da Semana:</strong> {{ formatDayOfWeek(plano.indexDia) }}</div>
                  <div><strong>Horários:</strong> {{ plano.horarios }}</div>
                </div>
              </div>
              <font-awesome-icon icon="trash" class="icon-trash" @click="confirmDeletePlano(plano.id)" />
            </li>
          </ul>
          <p v-else class="empty-info">Nenhum plano ativo encontrado.</p>
        </div>

        <!-- Seção de Horários Reservados -->
        <div class="client-section">
          <h5>Horários Reservados:</h5>
          <ul v-if="filteredHorarios.length > 0">
            <li v-for="horario in filteredHorarios" :key="horario.id">
  {{ formatDate(horario.data) }} {{ horario.horario }} - {{ horario.quadra.nome }}
  <p v-if="horario.idReserva !== null"> - faz parte do plano: {{ horario.idReserva }}</p>
              <font-awesome-icon icon="trash" class="icon-trash" @click="confirmDeleteHorario(horario.id)" />
            </li>
          </ul>
          <p v-else class="empty-info">Nenhum horário reservado encontrado.</p>
        </div>
      </div>
    </div>

    <!-- Modal de Confirmação -->
    <div v-if="showConfirmation" class="modal-overlay">
      <div class="modal-container">
        <h2>Atenção!</h2>
        <p>Você está prestes a excluir {{ deletingItemType }}. Esta ação não pode ser desfeita. Deseja continuar?</p>
        <div class="button-group">
          <button class="modal-button cancel" @click="cancelDelete">Cancelar</button>
          <button class="modal-button confirm" @click="confirmDelete">Excluir</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import axios from "axios";
import dayjs from "dayjs";
import HomeIcon from "../../components/HomeIcon.vue";

export default defineComponent({
  name: "AdmClients",
  components: { HomeIcon },
  setup() {
    const searchQuery = ref<string>("");
    const clients = ref<any[]>([]);
    const selectedClient = ref<any | null>(null);
    const loadingClients = ref(false);
    const loadingDetails = ref(false);
    const showDropdown = ref(false);
    const showConfirmation = ref(false);
    const itemToDelete = ref<number | null>(null);
    const deletingItemType = ref<string>("");
    const debounceTimeout = ref<ReturnType<typeof setTimeout> | null>(null);

    const searchClients = async () => {
      loadingClients.value = true;
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_BACKEND_URL}/searchClients?query=${searchQuery.value}`
        );
        clients.value = response.data;
      } catch (error) {
        console.error("Erro ao buscar usuários:", error);
      } finally {
        loadingClients.value = false;
      }
    };

    const onInput = () => {
      if (debounceTimeout.value) clearTimeout(debounceTimeout.value);
      debounceTimeout.value = setTimeout(async () => {
        if (searchQuery.value.trim() === "") {
          clients.value = [];
          return;
        }
        await searchClients();
      }, 300);
    };

    const selectClient = async (client: any) => {
      selectedClient.value = client;
      searchQuery.value = "";
      clients.value = [];
      showDropdown.value = false;
      loadingDetails.value = true;

      try {
        const horariosResponse = await axios.get(
          `${process.env.VUE_APP_BACKEND_URL}/horariosOcupados/${client.id}`
        );
        const planosResponse = await axios.get(
          `${process.env.VUE_APP_BACKEND_URL}/reservas/usuario/${client.id}`
        );
        selectedClient.value = {
          ...client,
          horarios: horariosResponse.data || [],
          planos: planosResponse.data.reservas || [],
        };
      } catch (error) {
        console.error("Erro ao buscar detalhes do usuário:", error);
      } finally {
        loadingDetails.value = false;
      }
    };

    const filteredHorarios = computed(() => {
      console.log(selectedClient.value.horarios)
      if (!selectedClient.value || !selectedClient.value.horarios) {
        return [];
      }
      const now = dayjs();
      return selectedClient.value.horarios.filter((horario: any) => {
        const horarioDateTime = dayjs(`${horario.data} ${horario.horario}`, "YYYY-MM-DD HH:mm");
        return horarioDateTime.isAfter(now);
      });
    });

    const filteredPlanos = computed(() => {
      if (!selectedClient.value || !selectedClient.value.planos) {
        return [];
      }
      const now = dayjs();
      return selectedClient.value.planos.filter((plano: any) => {
        const dataFim = dayjs(plano.dataFim, "YYYY-MM-DD");
        return dataFim.isAfter(now) || dataFim.isSame(now, "day");
      });
    });

    const confirmDeletePlano = (planoId: number) => {
      itemToDelete.value = planoId;
      deletingItemType.value = "plano";
      showConfirmation.value = true;
    };

    const confirmDeleteHorario = (horarioId: number) => {
      itemToDelete.value = horarioId;
      deletingItemType.value = "horario";
      showConfirmation.value = true;
    };

    const cancelDelete = () => {
      itemToDelete.value = null;
      deletingItemType.value = "";
      showConfirmation.value = false;
    };

    const confirmDelete = async () => {
      if (!itemToDelete.value) return;

      try {
        if (deletingItemType.value === "plano") {
          await axios.delete(`${process.env.VUE_APP_BACKEND_URL}/reservas/${itemToDelete.value}`);
        } else if (deletingItemType.value === "horario") {
          await axios.delete(`${process.env.VUE_APP_BACKEND_URL}/horariosOcupados/${itemToDelete.value}`);
        }
        await selectClient(selectedClient.value);
        cancelDelete();
        window.location.reload()
      } catch (error) {
        console.error(`Erro ao excluir ${deletingItemType.value}:`, error);
      }
    };

    const filteredClients = computed(() => {
      const query = searchQuery.value.toLowerCase().trim();
      return clients.value.filter((client) =>
        client.nome.toLowerCase().includes(query) || client.email.toLowerCase().includes(query)
      );
    });

    const formatDate = (date: string) => {
      return dayjs(date).format("DD/MM/YYYY");
    };

    const formatDayOfWeek = (indexDia: number) => {
      const daysOfWeek = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];
      return daysOfWeek[indexDia];
    };

    const handleOutsideClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target.closest(".search-section")) {
        showDropdown.value = false;
      }
    };

    return {
      searchQuery,
      formatDayOfWeek,
      clients,
      filteredClients,
      loadingClients,
      loadingDetails,
      showDropdown,
      selectedClient,
      filteredHorarios,
      onInput,
      selectClient,
      formatDate,
      handleOutsideClick,
      filteredPlanos,
      confirmDeletePlano,
      confirmDeleteHorario,
      confirmDelete,
      cancelDelete,
      showConfirmation,
      deletingItemType
    };
  },
});
</script>

<style scoped>
.admin-clients-page {
  padding: 40px;
  min-height: 100vh;
  background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  padding: 30px;
  border-radius: 15px;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

h3 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
  font-weight: bold;
  text-transform: uppercase;
}

.search-section {
  position: relative;
  width: 100%;
  margin-bottom: 30px;
}

.search-input {
  width: 100%;
  padding: 12px;
  border: 2px solid #ddd;
  border-radius: 10px;
  font-size: 1.2rem;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.client-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 250px;
  overflow-y: auto;
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 0;
  margin: 5px 0 0;
  z-index: 10;
  list-style: none;
}

.client-dropdown li {
  padding: 15px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.client-dropdown li:hover {
  background: #f5f5f5;
}

.no-results {
  margin-top: 10px;
  color: #888;
}

.client-details {
  margin-top: 20px;
  text-align: left;
}

.client-details h4 {
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 1.8rem;
  color: #444;
}

.client-details p {
  margin: 8px 0;
}

.client-section {
  margin-bottom: 25px;
}

.client-section h5 {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 1.5rem;
  color: #555;
}

.empty-info {
  color: #888;
  font-style: italic;
  margin-top: 10px;
}

.client-details ul {
  padding-left: 20px;
  margin: 10px 0 20px;
}

.client-details ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  background-color: #f9f9f9;
  border-radius: 8px;
  margin-bottom: 8px;
}

.icon-trash {
  margin-left: 10px;
  cursor: pointer;
  color: #e74c3c;
  transition: color 0.2s ease;
}

.icon-trash:hover {
  color: #c0392b;
}

/* Loader Centralizado */
.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader {
  margin-top: 10px;
  margin-bottom: 5px;
  width: 50px;
  height: 50px;
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #3498db;
  animation: spin 1s linear infinite;
}

.loader-container p {
  font-size: 1.2rem;
  color: #000;
  text-align: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-container {
  background-color: #ffffff;
  width: 400px;
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  animation: fadeIn 0.3s ease-in-out;
}

.modal-container h2 {
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 15px;
  color: #333;
}

.modal-container p {
  font-size: 1rem;
  margin-bottom: 20px;
  color: #555;
}

.button-group {
  display: flex;
  flex-direction: column; /* Organiza os botões verticalmente */
  gap: 10px; /* Espaçamento entre os botões */
}

.modal-button {
  padding: 12px 20px;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  border: none;
  transition: background 0.3s;
  width: 100%; /* Ocupa a largura completa do container */
}

.modal-button.cancel {
  background-color: #ff5c5c;
  color: #fff;
}

.modal-button.cancel:hover {
  background-color: #e04848;
}

.modal-button.confirm {
  background-color: #6200ea;
  color: #ffffff;
}

.modal-button.confirm:hover {
  background-color: #4b00b2;
}


/* Animação de entrada do modal */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@media (max-width: 1000px){
  .admin-clients-page{
    padding: 70px;
  }
}

/* Responsividade */
@media (max-width: 768px) {
  .container {
    padding: 20px;
  }

  h3 {
    font-size: 1.6rem;
  }

  .search-input {
    padding: 10px;
    font-size: 1rem;
  }

  .client-details h4 {
    font-size: 1.4rem;
  }

  .modal-container {
    width: 90%;
    padding: 20px;
  }
}
</style>
