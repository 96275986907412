<template>
    <div class="create-teacher-page">
      <HomeIcon :showArrow="true" to="adm-dashboard" />
  
      <form @submit.prevent="createTeacher" class="form-container">
        <h1>Criar Novo Professor</h1>
  
        <!-- Loader para a criação de professor -->
        <div v-if="loadingCreate" class="loader-container">
          <div class="loader"></div>
          <p>Enviando dados...</p>
        </div>
  
        <div v-if="!loadingCreate">
          <div class="form-group">
            <label for="nome">Nome do Professor</label>
            <input
              v-model="state.nome"
              type="text"
              id="nome"
              placeholder="Digite o nome do professor"
              @blur="validateField('nome')"
            />
            <span v-if="state.errors.nome" class="error">{{ state.errors.nome }}</span>
          </div>
  
          <div class="form-group">
            <label for="especialidade">Especialidade</label>
            <input
              v-model="state.especialidade"
              type="text"
              id="especialidade"
              placeholder="Digite a especialidade do professor"
              @blur="validateField('especialidade')"
            />
            <span v-if="state.errors.especialidade" class="error">{{ state.errors.especialidade }}</span>
          </div>
  
          <div class="form-group">
            <label for="descricao">Descrição</label>
            <textarea
              v-model="state.descricao"
              id="descricao"
              placeholder="Digite uma descrição"
              rows="4"
              @blur="validateField('descricao')"
            ></textarea>
            <span v-if="state.errors.descricao" class="error">{{ state.errors.descricao }}</span>
          </div>
  
          <button type="submit" class="button">Criar Professor</button>
        </div>
      </form>
  
      <!-- Tabela de professores -->
      <div class="teachers-list">
        <div v-if="loadingData" class="loader-container">
          <div class="loader"></div>
          <p>Carregando dados...</p>
        </div>
  
        <div v-if="loadingDelete" class="loader-container">
          <div class="loader"></div>
          <p>Excluindo professor...</p>
        </div>
  
        <div v-else>
          <h2>Professores Cadastrados</h2>
          <table>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Especialidade</th>
                <th>Descrição</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="teacher in teachers" :key="teacher.id">
                <td>{{ teacher.nome }}</td>
                <td>{{ teacher.especialidade }}</td>
                <td>{{ teacher.descricao }}</td>
                <td>
                  <div v-if="loadingDelete && deletingTeacherId === teacher.id" class="loader-container">
                    <div class="loader small"></div>
                  </div>
                  <div v-else>
                    <font-awesome-icon icon="trash" class="icon-trash" @click="confirmDeleteTeacher(teacher.id)" />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
  
      <!-- Modal de confirmação para excluir professor -->
      <div v-if="showConfirmation" class="confirmation-modal">
        <div class="modal-content">
          <h3>Confirmar Exclusão</h3>
          <p>Deseja realmente excluir este professor?</p>
          <div class="modal-actions">
            <button @click="cancelDeleteTeacher" class="btn-cancel">Cancelar</button>
            <button @click="confirmDelete" class="btn-confirm">Confirmar</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import axios from 'axios';
  import { defineComponent, reactive, ref, onMounted } from 'vue';
  import HomeIcon from '@/components/HomeIcon.vue';
  
  interface Teacher {
    id: number;
    nome: string;
    especialidade: string;
    descricao: string;
  }
  
  export default defineComponent({
    name: 'CreateTeacherPage',
    components: {
      HomeIcon
    },
    setup() {
      const teachers = ref<Teacher[]>([]);
      const showConfirmation = ref(false);
      const deletingTeacherId = ref<number | null>(null);
      const loadingData = ref(true);
      const loadingCreate = ref(false);
      const loadingDelete = ref(false);
  
      const state = reactive({
        nome: '',
        especialidade: '',
        descricao: '',
        errors: {
          nome: '',
          especialidade: '',
          descricao: ''
        }
      });
  
      const schema = {
  nome: (value: string) =>
    value.trim().length > 0 
      ? value.trim().length <= 30 || 'O nome deve ter no máximo 30 caracteres'
      : 'O nome é obrigatório',

  especialidade: (value: string) =>
    value.trim().length > 0 
      ? value.trim().length <= 50 || 'A especialidade deve ter no máximo 50 caracteres'
      : 'A especialidade é obrigatória',

  descricao: (value: string) =>
    value.trim().length > 0 
      ? value.trim().length <= 200 || 'A descrição deve ter no máximo 200 caracteres'
      : 'A descrição é obrigatória'
};
  
      const validateField = (field: keyof typeof state) => {
  const validationFunction = schema[field as keyof typeof schema];
  const value = state[field as keyof typeof state];

  const errorMessage = typeof value === 'string'
    ? validationFunction(value.trim())  // Aplica o trim apenas se for string
    : validationFunction(String(value)); // Converte para string, se necessário

  state.errors[field as keyof typeof state.errors] = errorMessage !== true ? errorMessage : '';
};
  
      const createTeacher = async () => {
        validateField('nome');
        validateField('especialidade');
        validateField('descricao');
  
        if (state.errors.nome || state.errors.especialidade || state.errors.descricao) {
          return;
        }
  
        loadingCreate.value = true;
  
        try {
          await axios.post(`${process.env.VUE_APP_BACKEND_URL}/professor`, {
            nome: state.nome,
            especialidade: state.especialidade,
            descricao: state.descricao
          });
          alert('Professor criado com sucesso!');
          state.nome = '';
          state.especialidade = '';
          state.descricao = '';
          fetchTeachers();
        } catch (error) {
          console.error('Erro ao criar professor:', error);
          alert('Erro ao criar professor');
        } finally {
          loadingCreate.value = false;
        }
      };
  
      const fetchTeachers = async () => {
        try {
          const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/professor`);
          teachers.value = response.data;
        } catch (error) {
          console.error('Erro ao buscar professores:', error);
        } finally {
          loadingData.value = false;
        }
      };
  
      const confirmDeleteTeacher = (id: number) => {
        deletingTeacherId.value = id;
        showConfirmation.value = true;
      };
  
      const cancelDeleteTeacher = () => {
        showConfirmation.value = false;
        deletingTeacherId.value = null;
      };
  
      const confirmDelete = async () => {
        if (deletingTeacherId.value) {
          loadingDelete.value = true;
          try {
            await axios.delete(`${process.env.VUE_APP_BACKEND_URL}/professor/${deletingTeacherId.value}`);
            alert('Professor excluído com sucesso!');
            fetchTeachers();
          } catch (error) {
            console.error('Erro ao excluir professor:', error);
            alert('Erro ao excluir professor');
          } finally {
            loadingDelete.value = false;
            showConfirmation.value = false;
            deletingTeacherId.value = null;
          }
        }
      };
  
      onMounted(() => {
        fetchTeachers();
      });
  
      return {
        state,
        teachers,
        createTeacher,
        validateField,
        showConfirmation,
        confirmDeleteTeacher,
        cancelDeleteTeacher,
        confirmDelete,
        loadingData,
        loadingCreate,
        loadingDelete,
        deletingTeacherId
      };
    }
  });
  </script>
  
  <style scoped>
.create-teacher-page {
  position: relative;
  width: 100%;
  padding: 60px;
  min-height: 100vh;
  background: linear-gradient(135deg, #FF6A00, #f01f84);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

h1 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #000000;
  font-weight: bold;
}

.form-container {
  background: white;
  padding: 40px;
  border-radius: 10px;
  width: 100%;
  max-width: 500px;
  text-align: center;
  margin-bottom: 30px;
}

.form-group {
  margin-bottom: 20px;
  text-align: left;
}

label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #333;
}

input,
select,
textarea {
  width: 100%;
  padding: 12px;
  font-size: 1.1rem;
  border-radius: 10px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  color: #333;
}

input:focus,
select:focus,
textarea:focus {
  border-color: #f857a6;
  outline: none;
}

textarea {
  resize: none;
}

.error {
  color: red;
  font-size: 0.9rem;
  margin-top: 8px;
}

.button {
  width: 100%;
  padding: 12px;
  font-size: 1.1rem;
  font-weight: bold;
  background-color: #f01f84;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(248, 87, 166, 0.3);
  transition: background-color 0.3s, transform 0.2s;
}

.button:hover {
  background-color: #ff5858;
}

.teachers-list {
  width: 100%;
  max-width: 800px;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

h2 {
  color: #f01f84;
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem;
}

table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
}

thead th {
  background-color: #f01f84;
  color: white;
  padding: 10px;
  border-bottom: 2px solid #ff6a00;
}

tbody td {
  padding: 15px;
  border-bottom: 1px solid #ccc;
  text-align: center;
  color: black;
}

tbody tr:hover {
  background-color: #f9f9f9;
}

.icon-trash {
  color: #ff5858;
  cursor: pointer;
}

.icon-trash:hover {
  transform: scale(1.2);
  transition: transform 0.2s;
}

.confirmation-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  color: black;
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  max-width: 500px;
  width: 100%;
}

.modal-content h3 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: black;
}

.modal-content p {
  font-size: 1rem;
  margin-bottom: 30px;
}

.modal-actions {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.btn-cancel,
.btn-confirm {
  padding: 15px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-cancel {
  background-color: #ff4c4c;
  color: white;
}

.btn-confirm {
  background-color: #4300a2 !important;
  color: white;
}

.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

.loader.small {
  width: 40px;
  height: 40px;
}

.loader-container p {
  font-size: 1.3rem;
  color: #000;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .create-teacher-page {
    padding: 60px;
  }

  .form-container {
    padding: 20px;
    width: 90%;
  }

  .teachers-list {
    padding: 15px;
  }

  h1,
  h2 {
    font-size: 1.5rem;
  }

  .form-group label,
  .form-group input,
  .form-group select {
    font-size: 0.9rem;
  }

  .form-group {
    margin-bottom: 15px;
  }

  .icon-trash {
    font-size: 1.2rem;
  }
}

@media (max-width: 500px) {
  table {
    display: block;
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
  }
}

  </style>
  