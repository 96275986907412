<template>
    <div class="select-plan-page">
      <HomeIcon :showArrow="true" to="adm-dashboard"/>
      <h1>Escolha o Plano</h1>
      <div class="container">
        <!-- Opções de planos -->
        <button @click="selectPlan(1)" class="plan-button" id="plan-button">
          Plano Mensal
        </button>
        <button @click="selectPlan(2)" class="plan-button">
          Plano Trimestral
        </button>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent } from 'vue';
  import { useRouter } from 'vue-router';
  import HomeIcon from '../../../components/HomeIcon.vue';
  
  export default defineComponent({
    name: 'SelectPlanPage',
    components: {
      HomeIcon,
    },
    setup() {
      const router = useRouter();
  
      // Função para selecionar o plano e navegar para a página de marcação de horários
      const selectPlan = (planId: number) => {
        // Navegar para a página de reservas, passando o plano selecionado como parâmetro
        router.push({
          name: 'AdmCreatePlans', // Certifique-se que a rota correta seja usada
          query: { planId },
        });
      };
  
      return {
        selectPlan,
      };
    },
  });
  </script>
  
  <style scoped>
  .select-plan-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
    color: white;
    padding: 40px 20px;
  }
  
  h1 {
    font-size: 2.5rem;
    font-weight: bold;
    text-transform: uppercase;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
    margin-bottom: 30px;
  }
  
  .container {
    background: white;
    color: #333;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #plan-button{
    margin-bottom: 10px;
  }
  
  .plan-button {
    background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
  border: none;
  color: white;
  padding: 15px 30px;
  font-size: 1.2rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 10px;
  transition: background 0.3s, transform 0.2s;
  width: 100%
  }
  
  .plan-button:hover {
    background-color: #FF6A00;
    transform: scale(1.05);
  }
  </style>
  