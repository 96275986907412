<!-- src/views/Login.vue -->
<template>
  <div class="login-container">
    <HomeIcon/>
    <div class="login-box">
      <h1>Login</h1>
      <div v-if="loading" class="loader-container">
        <div class="loader"></div>
        <p>Carregando dados...</p>
      </div>
      <form v-else @submit.prevent="handleLogin">
        <div class="form-group">
          <label for="email">Email</label>
          <input type="email" id="email" v-model="email" required />
        </div>
        <div class="form-group">
          <label for="senha">Senha</label>
          <input type="password" id="senha" v-model="senha" required />
        </div>
        <button type="submit">Entrar</button>
      </form>
      <p v-if="!loading">
        Não tem uma conta? <router-link to="/register">Faça seu cadastro</router-link>
      </p>
      <p v-if="!loading">
        Esqueceu a senha? <router-link to="/forgot-password">Redefinir senha</router-link>
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import axios from 'axios';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import HomeIcon from '../../components/HomeIcon.vue';

export default defineComponent({
  name: 'Login',
  components: {
    HomeIcon
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const email = ref<string | null>(null);
    const senha = ref<string | null>(null);
    const loading = ref<boolean>(false);

    const handleLogin = async () => {
  loading.value = true;
  try {
    const response = await axios.post(`${process.env.VUE_APP_BACKEND_URL}/login`, {
      email: email.value,
      senha: senha.value,
    }, { withCredentials: true });

    if (response.data.success) {
      store.commit('setAuthentication', true);
      store.commit('setUsuario', response.data)
      router.push('/');
    } else {
      alert('Login falhou. Por favor, verifique suas credenciais.');
    }
  } catch (error: any) {
    if (error.response && error.response.status === 401) {
      // Se a API retornar status 401, considere isso como erro de credenciais.
      alert('Credenciais inválidas. Por favor, verifique seu email ou senha.');
    } else {
      // Outros erros que podem ser erros de rede ou do servidor.
      console.error('Erro ao realizar login:', error);
      alert('Erro ao realizar login. Por favor, tente novamente.');
    }
  } finally {
    loading.value = false;
  }
};

    return {
      handleLogin,
      email,
      senha,
      loading,
    };
  },
});
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
}

.login-box {
  background: white;
  padding: 60px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 400px;
}

h1 {
  margin-bottom: 2rem;
  font-size: 2rem;
  color: #f01f84;
  text-transform: uppercase;
}

.form-group {
  margin-bottom: 1rem;
  text-align: left;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 700;
}

input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

button {
  text-transform: uppercase;
  background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
  margin-top: 1rem;
}

button:hover {
  background: linear-gradient(135deg, #f01f84 0%, #FF6A00 100%);
}

p {
  margin-top: 1rem;
  font-size: 14px;
}

.p2{
  font-size: 18px;
}

.router-link {
  color: #f01f84;
  text-decoration: none;
}

.router-link:hover {
  text-decoration: underline;
}


.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 16px solid #f3f3f3; 
  border-top: 16px solid #3498db; 
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: auto;
}

.loader-container p {
  font-size: 1.3rem; /* Texto mais proporcional */
  color: #000;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 768px) {
  .login-container {
    padding: 40px;
  }

  .login-box {
    width: 90%;
  }

  h1 {
    font-size: 1.8rem;
  }

  .form-group label {
    font-size: 0.9rem;
  }

  input {
    font-size: 0.9rem;
    padding: 0.6rem;
  }

  button {
    padding: 0.6rem;
    font-size: 1rem;
  }

  .loader-container p {
    font-size: 1.1rem;
  }
}

@media (max-width: 576px) {
  .login-container {
    padding: 20px;
  }

  .login-box {
    padding: 40px;
    width: 100%;
  }

  h1 {
    font-size: 1.8rem;
  }

  .form-group label {
    font-size: 0.8rem;
  }

  input {
    font-size: 0.85rem;
    padding: 0.5rem;
  }

  button {
    padding: 0.5rem;
    font-size: 0.9rem;
  }

  p {
    font-size: 0.85rem;
  }

  .loader {
    width: 80px;
    height: 80px;
    border-width: 12px;
  }

  .loader-container p {
    font-size: 1rem;
  }
}

</style>
