<template>
  <div class="select-time-page">
    <HomeIcon :showArrow="true" to="/calendar" />
    <div class="container">
      <div class="loader-container" v-if="loading">
        <div class="loader"></div>
        <p>Carregando dados...</p>
      </div>
      <div v-else>
        <div v-if="showPaymentWarning" class="modal-overlay">
          <div class="modal-container">
            <h2>Atenção!</h2>
            <p>
              Você tem 5 minutos para concluir o pagamento. Após esse tempo, sua sessão será expirada e os horários selecionados ficarão indisponíveis para pagamento. Após concluir o pagamento, espere a página de confirmação ser carregada para ter seu horário confirmado.
            </p>
            <div class="button-group">
              <button @click="cancelPayment" class="modal-button cancel">Cancelar</button>
              <button @click="confirmPayment" class="modal-button confirm">Continuar</button>
            </div>
          </div>
        </div>
        <div :class="{ 'blurred': showPaymentWarning }">
          <h1 class="title">Selecione seus horários para {{ formattedDate }}</h1>
          <div class="main-content">

            <h2>Horários disponíveis:</h2>
            
            <!-- Seção de legenda -->
            <div class="legend">
              <div class="legend-item">
                <span class="dot fifty-reais"></span> R$50,00 (antes de 17:00)
              </div>
              <div class="legend-item">
                <span class="dot eighty-reais"></span> R$80,00 (a partir de 17:00)
              </div>
            </div>

            <div v-for="court in sortedCourts" :key="court.id" class="court">
              <h3>{{ court.nome }} - {{ court.patrocinador.nome }}</h3>
              <div class="times">
                <button
                  v-for="time in availableTimes[court.id]"
                  :key="time.hour"
                  :class="{
                    disabled: !time.available,
                    selected: isSelected(court.id, time.hour),
                    'before-five': time.hour < '17:00', // Classe para horários antes das 17:00
                  }"
                  @click="selectTime(court.id, time.hour)"
                  :disabled="!time.available"
                >
                  {{ time.hour }}
                </button>
              </div>
            </div>

            <p class="description">
              É possível selecionar mais de um horário de uma vez, em diferentes quadras.
            </p>
            <div class="total-price">
              Valor total: R$ {{ totalPrice.toFixed(2) }}
            </div>
            <div v-if="discountApplied" class="discount-message">
            <p>
              Desconto aplicado por ter plano: {{ activePlanName }}
            </p>
            <p>(somente aos horários de 17:00 às 21:00)</p>
          </div>

            <button
              class="next-button"
              :class="{ clicked: nextButtonClicked }"
              @click="handleNextButtonClick()"
              @mousedown="nextButtonClicked = true"
              @mouseup="nextButtonClicked = false"
            >
              Próximo
            </button>
            <p v-if="showWarning" class="warning">
              Por favor, selecione um horário antes de prosseguir.
            </p>
            <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import dayjs from "dayjs";
import { loadStripe } from "@stripe/stripe-js";
import HomeIcon from "../../components/HomeIcon.vue";
import axios, { AxiosError } from "axios";
import jwtDecode from 'jwt-decode';

const stripePromise = loadStripe("pk_test_51Pw6F4KJmK61rZjcINJpjICXNPiw9me3GlzYgOnXSIMxIr678mVvFvy4vFDkpcORLeys7MPr8uxfll4cutJZ5z5q00izjyRGyN");

interface AvailableTime {
  hour: string;
  available: boolean;
}

interface Court {
  id: number;
  nome: string;
  patrocinador: {
    nome: string;
  };
}

export default defineComponent({
  name: "SelectTimePage",
  components: {
    HomeIcon,
  },
  setup() {
    const BASE_PRICE_PER_HOUR = 80;
    const PRICE_DISCOUNTS = {
      mensal: 75,
      trimestral: 65,
    };
    const DISCOUNTED_PRICE_BEFORE_17 = 50;

    const router = useRouter();
    const route = useRoute();
    const courts = ref<Court[]>([]);
    const availableTimes = ref<{ [courtId: number]: AvailableTime[] }>({});
    const selectedTimes = ref<{ court: number; hour: string }[]>([]);
    const loading = ref(true);
    const nextButtonClicked = ref(false);
    const showWarning = ref(false);
    const errorMessage = ref<string | null>(null);
    const activePlanName = ref<string | null>(null); // O nome do plano ativo
    const discountApplied = ref<boolean>(false); // Verifica se o desconto foi aplicado
    const selectedDate = ref<string | null>(null); // Data extraída do token
    const showPaymentWarning = ref(false);
    const user = ref<any>(null); // Dados do usuário

    const isBeforeFivePM = () => {
  const now = dayjs();
  console.log("Current hour:", now.hour(), "isBeforeFivePM:", now.hour() < 17);
  return now.hour() < 17;
};


    // Função para decodificar o token JWT
    const decodeToken = (token: string) => {
      try {
        const decoded = jwtDecode(token) as { date: string };
        return decoded.date; // Retorna a data presente no token
      } catch (error) {
        console.error("Erro ao decodificar o token JWT:", error);
        return null;
      }
    };

    // Função para formatar a data extraída do token
    const formattedDate = computed(() => {
      return selectedDate.value ? dayjs(selectedDate.value).format("DD/MM/YYYY") : "";
    });

    // Função para ordenar as quadras por número
    const sortedCourts = computed(() => {
      return courts.value.slice().sort((a, b) => {
        const numberA = parseInt(a.nome.match(/\d+/)?.[0] || "0");
        const numberB = parseInt(b.nome.match(/\d+/)?.[0] || "0");
        return numberA - numberB;
      });
    });

    const fetchCourts = async () => {
      try {
        const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/quadras`);
        courts.value = response.data;
      } catch (error) {
        console.error("Error fetching courts:", error);
      }
    };

    const fetchOccupiedTimes = async (date: string, courtId: number) => {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_BACKEND_URL}/horariosOcupados/${date}/${courtId}`
        );
        return response.data.map((item: { horario: string }) => item.horario);
      } catch (error) {
        console.error("Error fetching occupied times:", error);
        return [];
      }
    };

    // Adicione a função `handleNextButtonClick` no setup
const handleNextButtonClick = () => {
  if (selectedTimes.value.length === 0) {
    showWarning.value = true; // Exibe o aviso caso não haja horários selecionados
  } else {
    showPaymentWarning.value = true; // Exibe o modal de confirmação caso haja horários selecionados
  }
};


const generateAvailableTimes = async () => {
  const times: AvailableTime[] = [];
  const now = dayjs();

  if (!selectedDate.value) return;

  const dayOfWeek = dayjs(selectedDate.value).day();

  // Inicializar todos os horários de 07:00 às 21:00 como disponíveis
  for (let hour = 7; hour <= 21; hour++) {
    const time = dayjs(selectedDate.value).hour(hour).minute(0).second(0);
    const isAfterNow = dayjs(selectedDate.value).isAfter(now, "day") || time.isAfter(now);

    times.push({
      hour: time.format("HH:mm"),
      available: isAfterNow, // Inicializa como disponível se o horário ainda não passou
    });
  }

  for (const court of courts.value) {
    // Extrair o número da quadra
    const courtNumber = extractCourtNumber(court.nome);
    if (courtNumber === null) continue; // Ignorar se não houver número no nome da quadra

    // Usar a data formatada como YYYY-MM-DD ao buscar horários ocupados
    const occupiedTimes = await fetchOccupiedTimes(dayjs(selectedDate.value).format("YYYY-MM-DD"), court.id);

    // Atualizar horários como indisponíveis com base nos horários ocupados do banco
    let courtTimes = times.map((time) => ({
      ...time,
      available: !occupiedTimes.includes(time.hour), // Define como indisponível se estiver ocupado
    }));

    // Aplicar regras de disponibilidade para cada quadra
    if (dayOfWeek === 6 || dayOfWeek === 0) { // Fim de semana
      if (courtNumber !== 5) {
        courtTimes = courtTimes.map((time) => ({ ...time, available: false }));
      }
    } else {
      if (courtNumber === 1) {
        // Segunda a quinta-feira: 07:00 às 20:00 indisponível
        if (dayOfWeek >= 1 && dayOfWeek <= 4) {
          courtTimes = courtTimes.map((time) => ({
            ...time,
            available: !(time.hour >= "07:00" && time.hour <= "20:00"),
          }));
        }
        // Sexta-feira: 07:00 às 17:00 indisponível
        else if (dayOfWeek === 5) {
          courtTimes = courtTimes.map((time) => ({
            ...time,
            available: !(time.hour >= "07:00" && time.hour <= "17:00"),
          }));
        }
      } 
    }

    // Garantir que as quadras estejam associadas corretamente
    availableTimes.value[court.id] = courtTimes;
  }

  loading.value = false;
};

// Função para extrair o número da quadra do nome
const extractCourtNumber = (courtName: string): number | null => {
  const match = courtName.match(/\d+/); // Encontra o primeiro número no nome da quadra
  return match ? parseInt(match[0]) : null;
};



    const selectTime = (court: number, hour: string) => {
      const index = selectedTimes.value.findIndex(
        (t) => t.court === court && t.hour === hour
      );
      if (index === -1) {
        selectedTimes.value.push({ court, hour });
      } else {
        selectedTimes.value.splice(index, 1);
      }
    };

    const isSelected = (court: number, hour: string) => {
      return selectedTimes.value.some(
        (t) => t.court === court && t.hour === hour
      );
    };

    // Função para buscar os dados do usuário diretamente da API
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_BACKEND_URL}/account`,
          { withCredentials: true }
        );
        user.value = response.data.user;
        console.log("Dados do usuário:", user.value);
      } catch (error) {
        console.error("Erro ao buscar dados do usuário:", error);
      }
    };

    // Função para buscar os planos do usuário e verificar validade
    const fetchUserPlans = async () => {
      if (!user.value) return;

      try {
        const response = await axios.get(
          `${process.env.VUE_APP_BACKEND_URL}/reservas/usuario/${user.value.id}`,
          { withCredentials: true }
        );
        console.log(response)
        const userPlans = response.data.reservas || [];

        let validTrimestral = false;
        let validMensal = false;

        // Verificar se o usuário tem planos válidos
        for (const plan of userPlans) {
          if (plan.idPlano === 2 && dayjs(plan.dataFim).isAfter(dayjs())) {
            validTrimestral = true;
          } else if (plan.idPlano === 1 && dayjs(plan.dataFim).isAfter(dayjs())) {
            validMensal = true;
          }
        }

        if (validTrimestral) {
          activePlanName.value = "Trimestral";
          discountApplied.value = true;
        } else if (validMensal) {
          activePlanName.value = "Mensal";
          discountApplied.value = true;
        }

      } catch (error) {
        console.error("Erro ao obter planos do usuário:", error);
      }
    };

    const goToNextStep = async () => {
  loading.value = true;

  const selectedEntries = selectedTimes.value.map((t) => ({
    court: t.court,
    hour: t.hour,
  }));

  const userId = user.value?.id;

  if (!userId) {
    errorMessage.value = "User ID is missing";
    loading.value = false;
    return;
  }

  const formattedFinal = [(dayjs(selectedDate.value).format('YYYY-MM-DD'))];

  // Verificar o plano do usuário
  const userPlan = activePlanName.value ? activePlanName.value : "none"; // 'none' se não houver plano

  try {
    const response = await axios.post(
      `${process.env.VUE_APP_BACKEND_URL}/stripe/create-checkout-session`,
      {
        dates: formattedFinal,
        entries: selectedEntries,
        userId,
        plan: userPlan, // Incluindo o plano do usuário na requisição
      }
    );

    const sessionId = response.data.id;

    const stripe = await stripePromise;
    if (stripe) {
      stripe.redirectToCheckout({ sessionId });
    } else {
      console.error("Stripe.js failed to load.");
    }
  } catch (error) {
    const axiosError = error as AxiosError;
    if (
      axiosError.response &&
      axiosError.response.data &&
      (axiosError.response.data as any).error
    ) {
      errorMessage.value = (axiosError.response.data as any).error;
    } else {
      console.error("Error creating checkout session:", axiosError);
    }
  } finally {
    loading.value = false;
  }
};

    const cancelPayment = () => {
      showPaymentWarning.value = false;
    };

    const confirmPayment = () => {
      showPaymentWarning.value = false;
      goToNextStep();
    };

    onMounted(async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('token');

      if (token) {
        const dateFromToken = decodeToken(token);
        if (dateFromToken) {
          selectedDate.value = dateFromToken;
          console.log("Data extraída do token:", selectedDate.value);
          try {
            await fetchUserData(); // Buscar dados do usuário ao carregar a página
            await fetchCourts();
            await fetchUserPlans(); // Buscar planos do usuário
            await generateAvailableTimes();
          } catch (error) {
            console.error("Erro ao gerar horários:", error);
          } finally {
            loading.value = false;
          }
        } else {
          console.error("Data não encontrada no token.");
          loading.value = false;
        }
      } else {
        console.error("Token não encontrado.");
        loading.value = false;
      }
    });

    const totalPrice = computed(() => {
  return selectedTimes.value.reduce((total, time) => {
    // Define o preço para horários antes das 17:00 como R$50,00 fixo
    const isBeforeFive = time.hour < '17:00';
    let pricePerHour = isBeforeFive ? DISCOUNTED_PRICE_BEFORE_17 : BASE_PRICE_PER_HOUR;

    // Aplicar desconto nos horários após as 17:00 com base no plano ativo
    if (!isBeforeFive && discountApplied.value) {
      if (activePlanName.value === "Mensal") {
        pricePerHour -= 5; // Reduz R$5 para o plano mensal
      } else if (activePlanName.value === "Trimestral") {
        pricePerHour -= 15; // Reduz R$10 para o plano trimestral
      }
    }

    return total + pricePerHour;
  }, 0);
});

    return {
      fetchCourts,
      selectedDate,
      formattedDate,
      availableTimes,
      sortedCourts,
      selectTime,
      isSelected,
      goToNextStep,
      loading,
      nextButtonClicked,
      showWarning,
      errorMessage,
      totalPrice,
      discountApplied,
      activePlanName,
      showPaymentWarning,
      cancelPayment,
      confirmPayment,
      generateAvailableTimes,
      handleNextButtonClick,
      isBeforeFivePM
    };
  },
});
</script>


<style scoped>

.legend {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 15px;
}

.legend-item {
  display: flex;
  align-items: center;
  font-size: 1rem;
}

.dot {
  height: 12px;
  width: 12px;
  border-radius: 50%; /* Define a forma redonda */
  display: inline-block;
  margin-right: 5px;
  flex-shrink: 0; /* Impede que a bolinha seja redimensionada */
}

.fifty-reais {
  background-color: #f01f84;
}

.eighty-reais {
  background-color: #ff5858;
}

.select-time-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
  color: white;
  padding: 40px 20px;
}

.main-content {
  border-top: 5px solid #cd7f32;
  background: white;
  color: #333;
  width: 100%;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 1350px;
}

.title{
  text-transform: uppercase;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  font-size: 2.5rem;
}

.subtitle{
  font-size: 20px;
}

.court {
  margin-top: 10px;
  padding: 15px;
  background-color: #f7f7f7; /* Light background for courts section */
  border-radius: 8px;
}

.horariosDisponiveis{
  margin-top:10px;
  margin-bottom: -10px;
}

.court.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.times {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Espaçamento entre os botões */
  width: 100%; /* Contêiner dos botões ocupa a largura total */
}

.times button {
  flex-grow: 1;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: calc(100% / 15 - 10px);
  box-sizing: border-box;
}

.times button.before-five {
  background-color: #f01f84 !important; /* Amarelo dourado para horários antes das 17:00 */
}

/* Estilo de botão ativo e hover */
.times button:hover:not(.disabled) {
  background-color: #f857a6;
}

.times button.disabled {
  background-color: #ccc !important;
  cursor: not-allowed;
}

.times button:not(.disabled) {
  background-color: #ff5858;
  color: white;
}

.times button.selected {
  background-color: #4300a2 !important;
  color: white;
}

.times button.selected:hover {
  background-color: #6600f5 !important;
}

.description {
  color: #666;
  margin-top: 20px;
}

.descriptions {
  margin-top: 20px;
}

.total-price {
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 20px;
  color: #333;
}

.next-button {
  background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 5px;
  transition: background 0.3s;
  margin-top: 20px;
}

.next-button.clicked {
  background-color: #007bff;
  color: white;
}

.next-button.clicked:hover {
  background-color: #0056b3;
}

.next-button:hover {
  background: linear-gradient(135deg, #f01f84  0%, #FF6A00 100%);
}

.back-button {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background: white;
  color: #ff5858;
  text-decoration: none;
  border-radius: 5px;
  transition: background 0.3s;
}

.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: auto;
}

.loader-container p { 
  font-size: 1.5rem;
  margin-top: 10px;
  color: #ffffff;
  text-align: center; 
}

.error {
  color: #ff0000;
  font-weight: bold;
  margin-top: 20px;
  text-align: center;
  padding: 10px;
  background-color: #ffe6e6;
  border: 1px solid #ff0000;
  border-radius: 5px;
}

.warning {
  color: red;
  font-weight: bold;
  margin-top: 10px;
}
/* Modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-container {
  background-color: #ffffff;
  width: 400px;
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  animation: fadeIn 0.3s ease-in-out;
}

.modal-container h2 {
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 15px;
  color: #333;
}

.modal-container p {
  font-size: 1rem;
  margin-bottom: 20px;
  color: #555;
}

.button-group {
  display: flex;
  flex-direction: column; /* Organiza os botões verticalmente */
  gap: 10px; /* Espaçamento entre os botões */
}

.modal-button {
  padding: 12px 20px;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  border: none;
  transition: background 0.3s;
  width: 100%; /* Ocupa a largura completa do container */
}

.modal-button.cancel {
  background-color: #ff5c5c;
  color: #fff;
}

.modal-button.cancel:hover {
  background-color: #e04848;
}

.modal-button.confirm {
  background-color: #6200ea;
  color: #ffffff;
}

.modal-button.confirm:hover {
  background-color: #4b00b2;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@media (max-width: 1285px) {
  .times button {
    width: calc(100% / 5 - 10px); /* Ocupa metade da largura em telas menores */
  }
}

@media (max-width: 1024px) {
  .title {
    font-size: 2rem;
  }
  .select-time-page {
  padding: 60px 20px
  }
}

/* Responsivo para telas pequenas (tablets e smartphones) */
@media (max-width: 768px) {
  .title {
    font-size: 1.8rem;
  }
  .times button {
    width: calc(100% / 3 - 10px); /* Ocupa metade da largura em telas menores */
  }
}

@media (max-width: 480px) {
  .times button {
    width: 100%; /* Cada botão ocupa toda a linha em telas muito pequenas */
  }
}

</style>
