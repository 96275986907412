<template>
  <div class="select-day-page">
    <HomeIcon :showArrow="true" to="planos" v-if="!error"/>
    <h1 v-if="!error">Escolha o dia da semana</h1>
    <div v-if="error" class="error-message">
      <p>{{ error }}</p>
      <router-link :to="{ name: 'Planos' }" class="back-button">Voltar para Seleção de Planos</router-link>
    </div>
    <div v-else class="container">
      <select v-model="selectedDay" class="dropdown">
        <option disabled value="-1">Selecione um dia</option>
        <option v-for="(day, index) in daysOfWeek" :key="index" :value="index">
          {{ day }}
        </option>
      </select>
      <button @click="goToSelectTime" class="next-button">Próximo</button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import axios from 'axios';
import HomeIcon from '../../components/HomeIcon.vue';
import dayjs from 'dayjs';

export default defineComponent({
  name: 'SelectDayPage',
  components: {
    HomeIcon
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const daysOfWeek = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];
    const selectedDay = ref<number | null>(-1);
    const error = ref<string | null>(null); // Variável para exibir a mensagem de erro

    onMounted(() => {
      const planoId = route.query.planId ? parseInt(route.query.planId as string) : null;
      console.log("planId recebido:", planoId); // Verifique se o planoId está correto

      // Verificação inicial do planoId
      if (!planoId || isNaN(planoId) || (planoId < 1 || planoId > 2)) { // Apenas IDs 1 e 2 são válidos
        error.value = 'ID do plano inválido. Por favor, selecione um plano válido.'; 
      }
    });

    const getNextOccurrences = (dayIndex: number, startDate: string, durationInMonths: number): string[] => {
      const start = dayjs(startDate);
      const end = start.add(durationInMonths, 'month').add(1, 'week');
      const occurrences: string[] = [];
      let nextOccurrence = start;

      while (nextOccurrence.isBefore(end) || nextOccurrence.isSame(end, 'day')) {
        occurrences.push(nextOccurrence.format('YYYY-MM-DD'));
        nextOccurrence = nextOccurrence.add(1, 'week');
      }

      return occurrences;
    };

    const goToSelectTime = async () => {
      const planoId = route.query.planId ? parseInt(route.query.planId as string) : null;

      // Validação do planoId antes de continuar
      if (!planoId || isNaN(planoId) || (planoId < 1 || planoId > 2)) {
        error.value = 'ID do plano inválido. Por favor, selecione um plano válido.'; 
        return;
      }

      if (selectedDay.value !== null && selectedDay.value !== -1) {
        const durationInMonths = planoId === 1 ? 1 : 3;
        const today = dayjs();
        let firstOccurrence = today.day(selectedDay.value);

        if (firstOccurrence.isBefore(today) || firstOccurrence.isSame(today)) {
          firstOccurrence = firstOccurrence.add(1, 'week');
        }

        const nextOccurrences = getNextOccurrences(selectedDay.value, firstOccurrence.format('YYYY-MM-DD'), durationInMonths);
        const startDate = nextOccurrences[0];
        const endDate = dayjs(startDate).add(durationInMonths, 'month').format('YYYY-MM-DD');

        try {
          const response = await axios.post(`${process.env.VUE_APP_BACKEND_URL}/generate-token-mensal`, {
            dates: nextOccurrences,
            dayIndex: selectedDay.value,
            startDate: startDate,
            endDate: endDate,
            extraDate: nextOccurrences[nextOccurrences.length - 1],
          });

          const token = response.data.token;

          router.push({
            name: 'SelectTimeMensal',
            query: {
              token,
              planId: planoId.toString(), // Passe o planId como string
            },
          });
        } catch (error) {
          console.error('Erro ao gerar o token JWT:', error);
          alert('Erro ao gerar o token. Tente novamente mais tarde.');
        }
      } else {
        alert('Por favor, selecione um dia da semana.');
      }
    };

    return {
      daysOfWeek,
      selectedDay,
      goToSelectTime,
      error // Retorna a variável de erro para o template
    };
  }
});
</script>

<style scoped>
.select-day-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
  color: white;
  padding: 40px 20px;
  text-align: center;
}

.container {
  border-top: 5px solid #888888;
  background: white;
  color: #333;
  padding: 40px 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 500px;
  margin: 0 auto;
}

.error-message {
  background-color: #ffe6e6;
  color: #ff0000;
  border: 1px solid #ff0000;
  padding: 20px;
  border-radius: 8px;
  font-size: 1.2rem;
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
}

.back-button {
  margin-top: 15px;
  display: inline-block;
  padding: 10px 20px;
  color: white;
  background-color: #ff0000;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 30px;
  text-transform: uppercase;
  color: white;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}

.dropdown {
  width: 100%;
  padding: 15px;
  font-size: 1rem;
  font-family: 'Montserrat', sans-serif;
  border-radius: 10px;
  border: none;
  margin-bottom: 20px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
}

.next-button {
  background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
  border: none;
  color: white;
  padding: 15px 30px;
  font-size: 1.2rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 10px;
  transition: background 0.3s, transform 0.2s;
  margin-top: 30px;
}

.next-button:hover {
  background: linear-gradient(135deg, #f01f84 0%, #FF6A00 100%);
  transform: scale(1.05);
}
</style>
