<template>
  <div class="renovar-plano">
    <HomeIcon :showArrow="true" to="/account"/>
    <div class="loader-container" v-if="loading">
        <div class="loader"></div>
        <p>Carregando dados...</p>
      </div> <!-- Loader enquanto carrega -->
    
    <div v-else-if="plano && canRenew" class="container">
      <h2 class="h2">Renovação do Plano</h2>
      <div>
        <p><strong>Plano:</strong> {{ plano.reservaNome }}</p>
        <p><strong>Início:</strong> {{ formatDate(plano.dataInicio) }}</p>
        <p><strong>Fim:</strong> {{ formatDate(plano.dataFim) }}</p>
        <p><strong>Renovação para:</strong> {{ nextRenewalDate }}</p>
        
        <!-- Botão para prosseguir com o pagamento -->
        <button @click="prosseguirPagamento" class="btn-renew">Prosseguir para Pagamento</button>
      </div>
    </div>

    <div v-else-if="plano && !canRenew" class="container">
      <h2 class="h2">Renovação do Plano</h2>
      <p>O plano expirou ou já foi renovado.</p>
    </div>

    <div v-else class="container">
      <h2 class="h2">Renovação do Plano</h2>
      <p>Dados inválidos.</p>
    </div>
  </div>
</template>

<script lang="ts">
import axios from "axios";
import { defineComponent, ref, onMounted } from "vue";
import jwtDecode from "jwt-decode";
import dayjs from "dayjs";
import HomeIcon from "@/components/HomeIcon.vue";
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_test_51Pw6F4KJmK61rZjcINJpjICXNPiw9me3GlzYgOnXSIMxIr678mVvFvy4vFDkpcORLeys7MPr8uxfll4cutJZ5z5q00izjyRGyN');

export default defineComponent({
  name: "RenovarPlano",
  components: {
    HomeIcon
  },
  setup() {
    const plano = ref<any>(null);
    const nextRenewalDate = ref<string | null>(null);
    const canRenew = ref<boolean>(false);
    const loading = ref<boolean>(true);

    const fetchPlanoDetails = async () => {
      const token = new URLSearchParams(window.location.search).get("token");

      if (token) {
        try {
          // Decodificar o token para obter as informações básicas
          plano.value = jwtDecode(token);

          // Fazer a requisição ao backend para pegar os detalhes da reserva
          const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/reservas/${plano.value.reservaId}`);

          const reserva = response.data;

          if (reserva && reserva.dataFim) {
            const today = dayjs();
            const endPlanDate = dayjs(reserva.dataFim);

            // Verifica se a renovação é possível (apenas se estiver dentro dos últimos 5 dias antes da data final)
            const daysUntilEnd = endPlanDate.diff(today, 'day');

            // Verifica se o dia atual está antes de 5 dias da data final e não após a data final
            canRenew.value = daysUntilEnd >= -1 && daysUntilEnd <= 5;

            // Se puder renovar, calcule o próximo período de renovação (1 mês ou 3 meses)
            if (canRenew.value) {
              const planDuration = plano.value.planoId === 1 ? 1 : 3; // 1 mês para mensal, 3 para trimestral
              nextRenewalDate.value = endPlanDate.add(planDuration, 'month').format('DD/MM/YYYY');
            }
          }

        } catch (error) {
          console.error("Erro ao verificar a renovação:", error);
        } finally {
          loading.value = false;
        }
      } else {
        loading.value = false;
      }
    };

    const gerarDatas = (dataInicio: string, dataFim: string, planoId: number) => {
      const dayOfWeek = dayjs(dataInicio).day(); // Dia da semana do início do plano
      console.log(dayOfWeek);
      const datasGeradas: string[] = [];
      let novaEndDate = dayjs(dataFim).add(planoId === 1 ? 1 : 3, 'month'); // Define 1 ou 3 meses para a renovação

      let current = dayjs(dataFim);

      while (current.isBefore(novaEndDate)) {
        if (current.day() === dayOfWeek) {
          datasGeradas.push(current.format("YYYY-MM-DD"));
        }
        current = current.add(1, 'day');
      }

      const dataExtra = dayjs(datasGeradas[datasGeradas.length - 1]).add(7, 'day');
      const dataExtraString = dataExtra.format("YYYY-MM-DD");
      datasGeradas.push(dataExtraString);
      return { datasGeradas, novaEndDate: novaEndDate.format("YYYY-MM-DD") };
    };

    const prosseguirPagamento = async () => {
  // Revalidar a data de expiração do plano
  const today = dayjs();
  const endPlanDate = dayjs(plano.value.dataFim);
  const daysUntilEnd = endPlanDate.diff(today, 'day');
  
  // Verificar novamente se o plano ainda pode ser renovado
  if (daysUntilEnd <= -1 || daysUntilEnd > 5) {
    alert("O prazo de renovação expirou.");
    return;
  }

  try {
    loading.value = true;

    // Gerar as datas e configurar os dados para a sessão Stripe
    let { datasGeradas, novaEndDate } = gerarDatas(plano.value.dataInicio, plano.value.dataFim, plano.value.planoId);
    const userId = plano.value.userId;
    const quadraId = plano.value.idQuadra;
    const horariosString = plano.value.horarios || '';
    const horarios = horariosString.split(',').map((horario: string) => horario.trim());

    // Criar a sessão do Stripe
    datasGeradas.splice(0, 1);
    const response = await axios.post(`${process.env.VUE_APP_BACKEND_URL}/stripe/create-checkout-session-renovacao`, {
      dates: datasGeradas,
      horarios,
      userId,
      quadraId,
      plan: plano.value.planoId,
      endDate: novaEndDate,
      firstEndDate: plano.value.dataFim,
      reservaId: plano.value.reservaId
    });

    const stripe = await stripePromise;
    if (stripe) {
      stripe.redirectToCheckout({ sessionId: response.data.id });
    } else {
      console.error("Falha ao carregar o Stripe.");
    }
  } catch (error) {
    console.error("Erro ao prosseguir com o pagamento:", error);
  } finally {
    loading.value = false;
  }
};

    const formatDate = (date: string) => {
      return dayjs(date).format("DD/MM/YYYY");
    };

    onMounted(() => {
      fetchPlanoDetails();
    });

    return {
      plano,
      nextRenewalDate,
      canRenew,
      loading,
      prosseguirPagamento,
      formatDate,
    };
  },
});
</script>

<style scoped>
.renovar-plano {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
}

.p{
  color: white;
}

.container {
  background: white;
  border-radius: 10px;
  padding: 30px;
  max-width: 600px;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: auto;
}

.loader-container p { 
  font-size: 1.5rem;
  margin-top: 10px;
  color: #ffffff;
  text-align: center; 
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
  text-transform: uppercase;
  font-weight: 700;
}

p {
  font-size: 1.2rem;
  margin: 10px 0;
  color: #333;
}

.btn-renew {
  background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
 
  color: white;
  padding: 12px 24px;
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 5px;
  transition: background 0.3s, transform 0.3s;
  border: none;
}

.btn-renew:hover {
  background: linear-gradient(135deg, #f01f84 0%, #FF6A00 100%);
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .container {
    padding: 20px;
  }

  h2 {
    font-size: 2rem;
  }

  p {
    font-size: 1rem;
  }

  .btn-renew {
    font-size: 0.875rem;
    padding: 10px 20px;
  }
}

@media (max-width: 495px) {
  .container {
    padding: 15px;
  }

  h2 {
    font-size: 1.8rem;
  }

  p {
    font-size: 0.9rem;
  }

  .btn-renew {
    font-size: 0.8rem;
    padding: 8px 16px;
  }
}
</style>
