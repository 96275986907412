<template>
  <div class="expiring-plans-page">
    <HomeIcon :showArrow="true" to="adm-dashboard"/>
    <div class="container">
      <h1 class="title">Planos Próximos ao Vencimento</h1>

      <!-- Loader enquanto carrega -->
      <div class="loader-container" v-if="loading">
        <div class="loader"></div>
        <p>Carregando planos...</p>
      </div>

      <!-- Lista de planos -->
      <div v-if="!loading && expiringPlans.length > 0">
        <div v-for="plan in expiringPlans" :key="plan.id" class="plan-card">
          <h2 class="plan-title">{{ plan.clienteNome }}</h2>
          <p><strong>Data de Início:</strong> {{ formatDate(plan.dataInicio) }}</p>
          <p><strong>Data de Término:</strong> {{ formatDate(plan.dataFim) }}</p>
          <p><strong>Faltam:</strong> {{ calculateDaysLeft(plan.dataFim) }} dias</p>
        </div>
      </div>

      <!-- Mensagem caso não tenha planos -->
      <div v-if="!loading && expiringPlans.length === 0">
        <p>Não há planos expirando nos próximos 5 dias.</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import axios from 'axios';
import dayjs from 'dayjs';
import HomeIcon from '../../components/HomeIcon.vue';

// Definir a interface dos planos
interface Plan {
  id: number;
  clienteNome: string;
  dataInicio: string;
  dataFim: string;
}

export default defineComponent({
  name: 'AdmCheckPlans',
  components: {
    HomeIcon,
  },
  setup() {
    const loading = ref(true);
    const expiringPlans = ref<Plan[]>([]); // Array tipado com a interface Plan

    // Função para formatar datas
    const formatDate = (date: string) => {
      return dayjs(date).format('DD/MM/YYYY');
    };

    // Função para calcular quantos dias faltam para o plano expirar
    const calculateDaysLeft = (endDate: string) => {
      const today = dayjs();
      const expirationDate = dayjs(endDate);
      return expirationDate.diff(today, 'day');
    };

    // Função para buscar os planos que estão próximos de vencer (menos de 5 dias)
    const fetchExpiringPlans = async () => {
      try {
        const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/expiring`);
        expiringPlans.value = response.data.filter((plan: Plan) => calculateDaysLeft(plan.dataFim) <= 5);
      } catch (error) {
        console.error('Erro ao buscar os planos próximos do vencimento:', error);
      } finally {
        loading.value = false;
      }
    };

    onMounted(() => {
      fetchExpiringPlans();
    });

    return {
      loading,
      expiringPlans,
      formatDate,
      calculateDaysLeft,
    };
  },
});
</script>
  
  <style scoped>
  .expiring-plans-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
    color: white;
    padding: 40px 20px;
  }
  
  .title {
    text-transform: uppercase;
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .container {
    background: white;
    color: #333;
    width: 100%;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 1350px;
  }
  
  .plan-card {
    background-color: #f7f7f7;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 15px;
    width: 100%;
    max-width: 600px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
  }
  
  .plan-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .loader-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .loader {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    margin: auto;
  }
  
  .loader-container p {
    font-size: 1.5rem;
    margin-top: 10px;
    color: #ffffff;
    text-align: center;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  </style>
  