<template>
  <div class="select-day-page">
    <HomeIcon :showArrow="true" to="adm-choose-plan"/>
    <h1>Escolha o dia da semana</h1>
    <div class="container">
      <select v-model="selectedDay" class="dropdown">
        <option disabled value="-1">Selecione um dia</option>
        <option v-for="(day, index) in daysOfWeek" :key="index" :value="index">
          {{ day }}
        </option>
      </select>
      <button @click="goToSelectTime" class="next-button">Próximo</button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios'; // Para fazer a requisição ao backend
import HomeIcon from '../../../components/HomeIcon.vue';
import dayjs from 'dayjs';
import { start } from 'repl';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'AdmCreatePlans',
  components: {
    HomeIcon
  },
  setup() {
    const router = useRouter();
    const daysOfWeek = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];
    const selectedDay = ref<number | null>(-1);
      const route = useRoute();

    const getNextOccurrences = (dayIndex: number, startDate: string, durationInMonths: number): string[] => {
      const start = dayjs(startDate);
      const end = start.add(durationInMonths, 'month').add(1, 'week');
      const occurrences: string[] = [];
      let nextOccurrence = start;

      while (nextOccurrence.isBefore(end) || nextOccurrence.isSame(end, 'day')) {
        occurrences.push(nextOccurrence.format('YYYY-MM-DD'));
        nextOccurrence = nextOccurrence.add(1, 'week');
      }

      return occurrences;
    };


    const goToSelectTime = async () => {
  // Verifica se o idPlano está presente na query
  const planoId = route.query.planId ? parseInt(route.query.planId as string) : null;
  
  if (!planoId) {
    alert('Por favor, selecione um plano primeiro.');
    router.push({ name: 'AdmChoosePlan' });
    return; // Impede que o código continue executando
  }

  if (selectedDay.value !== null && selectedDay.value !== -1) {
    const durationInMonths = planoId === 1 ? 1 : 3; // Define 1 mês para mensal e 3 para trimestral

    const today = dayjs(); // Data de hoje
    let firstOccurrence = today.day(selectedDay.value);
    if (firstOccurrence.isBefore(today) || firstOccurrence.isSame(today)) {
      firstOccurrence = firstOccurrence.add(1, 'week');
    }

    const nextOccurrences = getNextOccurrences(selectedDay.value, firstOccurrence.format('YYYY-MM-DD'), durationInMonths);
    const startDate = nextOccurrences[0]; // Data inicial (primeira ocorrência)
    const endDate = dayjs(startDate).add(durationInMonths, 'month').format('YYYY-MM-DD'); // Data final baseada no plano

    try {
      const response = await axios.post(`${process.env.VUE_APP_BACKEND_URL}/generate-token-mensal`, {
        dates: nextOccurrences, // Envia as datas geradas
        dayIndex: selectedDay.value,
        startDate: startDate,
        endDate: endDate,
        extraDate: nextOccurrences[nextOccurrences.length - 1], // Data extra
      });

      const token = response.data.token;

      // Redireciona para a próxima página com o token e a duração do plano
      router.push({
        name: 'AdmFinalPlans',
        query: {
          token,
          idPlano: planoId, // Passa o ID do plano também
        },
      });
    } catch (error) {
      console.error('Erro ao gerar o token JWT:', error);
      alert('Erro ao gerar o token. Tente novamente mais tarde.');
    }
  } else {
    alert('Por favor, selecione um dia da semana.');
  }
};

    return {
      daysOfWeek,
      selectedDay,
      goToSelectTime,
    };
  }
});
</script>

<style scoped>
.select-day-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
  color: white;
  padding: 40px 20px;
  text-align: center;
}

.container {
  border-top: 5px solid #888888;
  background: white;
  color: #333;
  padding: 40px 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 500px;
  margin: 0 auto;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 30px;
  text-transform: uppercase;
  color: white;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}

.dropdown {
  width: 100%;
  padding: 15px;
  font-size: 1rem;
  font-family: 'Montserrat', sans-serif;
  border-radius: 10px;
  border: none;
  margin-bottom: 20px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
}

.next-button {
  background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
  border: none;
  color: white;
  padding: 15px 30px;
  font-size: 1.2rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 10px;
  transition: background 0.3s, transform 0.2s;
  margin-top: 30px;
}

.next-button:hover {
  background: linear-gradient(135deg, #f01f84 0%, #FF6A00 100%);
  transform: scale(1.05);
}
</style>
