<template>
  <div class="calendar-view">
    <v-calendar
      :style="{ width: '700px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', border: '2px solid #ccc', borderRadius: '10px' }"
      is-expanded
      :attributes="attrs"
      :min-date="today"
      :max-date="maxDate"
      @dayclick="onDayClick"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, PropType } from 'vue';
import dayjs from 'dayjs';

export default defineComponent({
  name: 'CalendarView',
  props: {
    selectedDate: {
      type: Date as PropType<Date | null>, // Permitir nulo se não houver data selecionada
      default: null,
    },
  },
  emits: ['update:selectedDate'], // Emitir evento quando a data for selecionada
  setup(props, { emit }) {
    const today = dayjs().startOf('day').toDate();
    const maxDate = dayjs().add(6, 'day').endOf('day').toDate(); // Limite de 7 dias a partir de hoje

    // Gerando atributos de exibição, como a data de hoje e a data selecionada
    const attrs = computed(() => [
      {
        key: 'today',
        dot: 'red',
        dates: today,
      },
      {
        key: 'selectedDate',
        dates: props.selectedDate,
        highlight: true,
        class: 'selected',
      },
      {
    key: 'weekends', // Estilo para fins de semana
    dates: { start: dayjs().day(6), end: dayjs().add(1, 'month').day(0) }, // Todos os sábados e domingos
    customData: { isWeekend: true }, // Para adicionar lógica de estilo no template
    class: 'weekend',
  }
    ]);

    const onDayClick = (day: any) => {
      const date = dayjs(day.date).startOf('day');

      if (date.isBefore(today) || date.isAfter(maxDate)) {
        alert('A data selecionada está fora do intervalo permitido.');
        return;
      }

      const dayOfWeek = date.day();

      // Se clicar na mesma data, deseleciona
      if (props.selectedDate && dayjs(props.selectedDate).isSame(date, 'day')) {
        emit('update:selectedDate', null); // Deseleciona a data
      } else {
        emit('update:selectedDate', date.toDate()); // Seleciona a nova data
      }
    };

    return {
      attrs,
      onDayClick,
      today,
      maxDate,
    };
  },
});
</script>

<style scoped>
.calendar-view {
  padding: 20px;
  max-width: 100%;
  width: 100%; /* Certifique-se de que ele se adapta ao tamanho da tela */
  display: flex;
  justify-content: center; /* Centralizar o calendário */
}

.vc-day {
  font-size: 1rem; /* Tamanho de fonte adequado para o calendário */
}

.vc-container {
  max-width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center; /* Mantém o calendário centralizado */
}

.custom-calendar .vc-title {
  font-size: 24px; /* Ajuste o tamanho da fonte do título */
}

.weekend {
  color: #ccc; /* Cor mais clara para indicar que não é clicável */
  pointer-events: none; /* Remove a capacidade de clicar */
}

/* Responsividade para diferentes tamanhos de tela */
@media (max-width: 768px) {
  .calendar-view {
    padding: 15px;
    width: 100%;
  }

  .vc-container {
    width: 100%;
    max-width: 100%;
    overflow-x: auto; /* Ativa a rolagem horizontal se necessário */
  }

  .custom-calendar .vc-title {
    font-size: 20px; /* Ajusta o tamanho do título em telas menores */
  }

  .vc-day {
    font-size: 0.9rem; /* Ajuste o tamanho dos dias no calendário */
  }
}

@media (max-width: 480px) {
  .calendar-view {
    padding: 10px;
  }

  .custom-calendar .vc-title {
    font-size: 18px; /* Diminui mais o título em dispositivos móveis */
  }

  .vc-day {
    font-size: 0.8rem; /* Tamanho dos dias mais adequado para smartphones */
  }
}

</style>
