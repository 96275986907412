import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_HomeBanner = _resolveComponent("HomeBanner")!
  const _component_WelcomeBanner = _resolveComponent("WelcomeBanner")!
  const _component_PlanosBanner = _resolveComponent("PlanosBanner")!
  const _component_QuadrasBanner = _resolveComponent("QuadrasBanner")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Header),
    _createVNode(_component_HomeBanner),
    _createVNode(_component_WelcomeBanner),
    _createVNode(_component_PlanosBanner),
    _createVNode(_component_QuadrasBanner),
    _createVNode(_component_Footer)
  ]))
}