<template>
  <div>
    <Header />
    <div class="plans-page">
      <div class="overlay">
        <div class="plans">
          <div class="plan">
            <h2>Plano Mensal</h2>
            <p>Reserve um horário específico por semana, durante um mês. Aproveite um desconto em relação ao pagamento avulso.</p>
            <ul>
              <li>Reserva fixa semanal</li>
              <li>Desconto em relação ao pagamento avulso</li>
              <li>Cancelamento gratuito</li>
            </ul>
          </div>
          <div class="plan">
            <h2>Plano Trimestral</h2>
            <p>Reserve um horário específico por semana, por três meses. Desconto adicional em relação ao plano mensal e ao pagamento avulso.</p>
            <ul>
              <li>Reserva fixa semanal</li>
              <li>Desconto em relação ao plano mensal e avulso</li>
              <li>Cancelamento gratuito</li>
            </ul>
          </div>
          <div class="plan">
            <h2>Avulso (Sem Plano)</h2>
            <p>Pague por uso, ideal para quem joga ocasionalmente e prefere flexibilidade total. Sem compromisso de renovação.</p>
            <ul>
              <li>Acesso às quadras por hora</li>
              <li>Pagamento por uso</li>
            </ul>
          </div>
        </div>
        <router-link to="/planos">
          <button>VER PLANOS</button>
        </router-link>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";

export default defineComponent({
  name: "PlansPage",
  components: {
    Header,
    Footer,
  },
});
</script>

<style scoped>
.plans-page {
  position: relative; /* Mantém o fluxo normal do documento */
  width: 100%;
  min-height: 100vh; /* Garante que a altura mínima seja 100% da janela */
  background: url('@/assets/quadras.jpg') no-repeat center center fixed;
  background-size: cover;
  display: flex;
  flex-direction: column; /* Alinha os elementos dentro da página */
}

.overlay {
  position: relative; /* Mantém o overlay no fluxo da página */
  width: 100%;
  min-height: 100vh;
  padding: 40px 20px;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.plans {
  display: flex;
  flex-direction: column;
  gap: 25px;
  max-width: 700px;
  width: 100%;
  z-index: 1;
}

.plan {
  background: linear-gradient(to top, #f01f84, #FF6A00);
  color: white;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
  text-align: left;
  width: 100%;
  transition: transform 0.3s, box-shadow 0.3s;
}

.plan:hover {
  transform: translateY(-5px);
}

.plan h2 {
  margin-bottom: 10px;
  font-size: 1.8em;
}

.plan p {
  margin-bottom: 12px;
  font-size: 1.1em;
}

.plan ul {
  list-style-type: none;
  padding: 0;
}

.plan ul li {
  background: rgba(255, 255, 255, 0.3);
  margin: 8px 0;
  padding: 10px;
  border-radius: 8px;
  color: white;
}

button {
  margin-top: 25px;
  padding: 12px 25px;
  background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2em;
  font-weight: bold;
  transition: background 0.3s, transform 0.3s;
}

button:hover {
  background: linear-gradient(135deg, #f01f84 0%, #FF6A00 100%);
  transform: scale(1.05);
}

</style>
