<template>
  <div id="app" class="container">
    <router-view />
    <!-- Verifique se deve ou não exibir o ChatBot com base na rota -->
    <ChatBot v-if="showChatBot" />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useRoute } from 'vue-router';
import ChatBot from './components/ChatBot.vue';

export default defineComponent({
  name: 'App',
  components: {
    ChatBot
  },
  setup() {
    const route = useRoute();

    const hiddenRoutes = ['/terms', '/privacy', '/login', '/register', '/forgot-password', '/reset-password', '/forgot-password', '/confirm-email']; 

    const showChatBot = computed(() => !hiddenRoutes.includes(route.path));

    return { showChatBot };
  }
});
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
html, body {
  width: 100%;
  height: 100%;
}
.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
}
</style>
